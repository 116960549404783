import * as React from 'react'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import EmptyImagePlaceholder from 'components/image/assets/empty-list.png'
import useResize from 'map/useResize'
import { windowSizeBreakpoints } from 'utils/breakpoints'

const IMAGE_HEIGHT_MOBILE = 300
const IMAGE_HEIGHT_TABLET = 450

interface Props {
  origin: 'android' | 'iPhone'
}

const InstallAppModal = ({ origin }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const [height] = useResize()

  return (
    <ContentModal>
      <EmptyImageContainer height={height}>
        <EmptyImage src={EmptyImagePlaceholder} aria-hidden />
      </EmptyImageContainer>
      <TitleModal>{i18n.t('components.installApp.title')}</TitleModal>
      <Button
        label={i18n.t('components.installApp.download')}
        onClick={() => {
          if (origin === 'android') {
            window.location.replace(
              'https://play.google.com/store/apps/details?id=com.engie.myportal.campus&hl=fr&gl=US'
            )
          } else {
            window.location.replace('https://apps.apple.com/en/app/living-by-my-portal/id1570642769')
          }
        }}
        color={Theme.colors.blue}
        textColor={Theme.colors.white}
        font={Theme.fonts.h3Bold}
        noFlex
      />
      <CloseText onClick={Modal.close}>{i18n.t('components.installApp.back')}</CloseText>
    </ContentModal>
  )
}

export default InstallAppModal

const ContentModal = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  justify-content: flex-end;
  height: 308px;
`

const TitleModal = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0;
  padding-bottom: 16px;
  z-index: 2;
`

const CloseText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.blue};
  margin: 0;
  padding-top: 16px;
  text-decoration: underline;
`

const EmptyImageContainer = styled('div')<{ height: number }>`
  position: absolute;
  top: ${(props) => props.height / 2 - IMAGE_HEIGHT_TABLET + 40}px;
  left: calc(50% - 339px);
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    top: ${(props) => props.height / 2 - IMAGE_HEIGHT_MOBILE + 40}px;
    left: 40px;
    right: 40px;
  }
`

const EmptyImage = styled('img')`
  height: ${IMAGE_HEIGHT_TABLET}px;
  width: 678px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    height: ${IMAGE_HEIGHT_MOBILE}px;
    width: 100%;
  }
`
