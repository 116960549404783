import * as React from 'react'
import styled from 'theme/styled-components'
import Theme from 'theme/Theme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import api from 'map/mapApi'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import Modal from 'components/modal/Modal'
import Icons from 'components/icons/Icons'
import Icon from 'components/icons/Icons'
import PlaceSearch, { Status } from 'components/map/PlaceSearch'
import RoomInfo from 'intervention/components/RoomInfo'

interface Props {
  setSelectedRoom: (room: AreaV6) => void
  selectedFloor?: number
  selectedFloorBuilding?: string
  selectedBuilding?: string
  setNoLocation: () => void
  hideLocation?: boolean
  goBack?: () => void
}

const RoomSelector = ({
  setSelectedRoom,
  selectedFloor,
  setNoLocation,
  hideLocation,
  selectedBuilding,
  selectedFloorBuilding,
  goBack,
}: Props) => {
  const i18n = useI18n()

  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)

  const isSelectedFloorNotNull = !!selectedFloor || selectedFloor === 0

  const [resultList, setResultList] = React.useState<AreaV6[]>([])
  const [searchText, setSearchText] = React.useState('')
  const [researchStatus, setResearchStatus] = React.useState<Status>(isSelectedFloorNotNull ? 'loading' : 'notStarted')

  const searchPlaces = (searchText: string) => {
    if (site && user && (searchText.length > 2 || isSelectedFloorNotNull)) {
      setResearchStatus('loading')
      api
        .getAllSearchedPlaces(searchText, site.id, user.type, selectedFloor, selectedBuilding || selectedFloorBuilding)
        .then((res) => {
          setResultList(res.areas.filter((area) => !!area.hasIntervention))
          setResearchStatus('ready')
        })
        .catch((err) => {
          setResearchStatus('error')
          Logger.error(err)
        })
    }
  }

  const renderRoomInfo = (room: AreaV6, i: number) => (
    <RoomInfo setSelectedRoom={setSelectedRoom} key={room.id} room={room} isLast={resultList.length === i + 1} />
  )

  return (
    <MainContainer>
      <TitleContainer>
        {!!goBack && (
          <CrossContainer
            onClick={() => {
              goBack()
              Modal.close()
            }}>
            <Icons name="chevron_left" size={25} color={Theme.colors.blue} />
          </CrossContainer>
        )}

        <Title>{i18n.t('screens.incident.form.whichLocation')}</Title>
        <CrossContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </CrossContainer>
      </TitleContainer>
      <PlaceSearch
        listElement={renderRoomInfo}
        resultList={resultList}
        setResultList={setResultList}
        searchText={searchText}
        setSearchText={setSearchText}
        researchStatus={researchStatus}
        setResearchStatus={setResearchStatus}
        searchPlaces={searchPlaces}
        height="calc(100% - 152px)"
        autoFocus={true}
        withoutSearchMinimum={isSelectedFloorNotNull}
      />
      {!hideLocation && (
        <NoLocationContainer
          onClick={() => {
            setNoLocation()
            Modal.close()
          }}>
          <Label>{i18n.t('screens.incident.locationNotInListQuestion')}</Label>

          <Icon size={16} name="arrow_right" color={Theme.colors.black} />
        </NoLocationContainer>
      )}
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 444px;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`

const NoLocationContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 24px;
  padding: 16px;
  cursor: pointer;
  border: 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 20px;
  }
`

const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`

const Label = styled('h1')`
  ${(props) => props.theme.fonts.label};
  flex: 1;
  text-align: center;
`

export default RoomSelector
