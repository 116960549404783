import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import ReservationModal from './ReservationModal'
import SelectionOnPlanModal from './SelectionOnPlanModal'

import api from '../api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import { findPriorityError, sortDesksNames } from '../utils'
import Logger from 'utils/Logger'

import useI18n from 'i18n/useI18n'
import Loader from 'react-loader-spinner'

interface Props {
  initialDate: Date
  location: ZapfloorZone
  closeModal: () => void
  userId: string
  teamId: string
  refreshData: () => void
  reservationTime: ReservationTime
  navigation: Navigation
  selectedAreas: AreaLightV6[]
  fromFavoriteZone?: boolean
  zapfloorPois: AreaLightV6[]
  parkingMode: boolean
  visitorMode: boolean
  visitorInfos?: UserResponse
  selectedVisitor?: PersonalVisitV6
  visits: PersonalVisitV6[]
}

const DeskSelectionModal = ({
  initialDate,
  location,
  closeModal,
  userId,
  teamId,
  refreshData,
  reservationTime,
  navigation,
  selectedAreas,
  fromFavoriteZone,
  zapfloorPois,
  parkingMode,
  visitorMode,
  visitorInfos,
  selectedVisitor,
  visits,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [viewMore, setViewMore] = React.useState(false)
  const [deskAreas, setDeskAreas] = React.useState<ZapfloorDeskArea[]>([])
  const [isWaiting, setIsWaiting] = React.useState(false)

  const errorMessage = findPriorityError(location.attributes.error_response, visitorMode)

  const deskAreasAvailable = React.useMemo(
    () => (!isWaiting ? deskAreas.filter((desk) => !desk.zapfloorDesk.attributes.hot_desk_in_use) : []),
    [deskAreas, isWaiting]
  )
  const firstData = React.useMemo(() => deskAreasAvailable.slice(0, 3), [deskAreasAvailable])

  const isNoPriority = React.useMemo(
    () => errorMessage === 'max_bookings_reached_provided_date' && deskAreasAvailable.length !== 0 && !visitorMode,
    [deskAreasAvailable]
  )

  React.useEffect(() => {
    if (!!user && !!currentSite && !!currentSite.locationId) {
      setIsWaiting(true)
      api.zapfloor
        .getHotDeskAvailable(
          user.type,
          currentSite.id,
          i18n.t('screens.planning.secondForm.dateToSend', {
            date: initialDate,
          }),
          currentSite.locationId,
          reservationTime,
          teamId,
          userId,
          location.attributes.unit_id
        )
        .then((res) => {
          const newDeskAreas = selectedAreas.reduce((prev, curr) => {
            const currDesk = res.find((desk) => desk.attributes.hot_desk_id === curr.zapfloorId)
            return !!currDesk ? [...prev, { zapfloorDesk: currDesk, area: curr }] : prev
          }, [] as ZapfloorDeskArea[])

          setDeskAreas(sortDesksNames(newDeskAreas))
        })
        .catch((err) => Logger.error('Error when fetching hot desks available : ', err))
        .finally(() => setIsWaiting(false))
    }
  }, [])

  const selectDeskArea = (deskArea?: ZapfloorDeskArea) => {
    Modal.open(() => (
      <ReservationModal
        userId={userId}
        teamId={teamId}
        closeModal={Modal.close}
        location={location}
        initialDate={initialDate}
        refreshData={refreshData}
        reservationTime={reservationTime}
        deskArea={deskArea}
        fromFavoriteZone={fromFavoriteZone}
        parkingMode={parkingMode}
        visitorMode={visitorMode}
        visitorInfos={visitorInfos}
        selectedVisitor={selectedVisitor}
        visits={visits}
        zapfloorPois={zapfloorPois.filter((poi) => !!poi.zapfloorId && poi.uniquePlaceName.includes('/'))}
      />
    ))
  }

  const chooseOnPlan = () => {
    const findPoi = zapfloorPois.find((poi) => poi.zapfloorId === location.attributes.unit_id)
    Modal.open(
      () => (
        <SelectionOnPlanModal
          userId={userId}
          teamId={teamId}
          closeModal={Modal.close}
          reservationTime={reservationTime}
          refreshData={refreshData}
          unitAreaUniqueName={!!findPoi ? findPoi.uniquePlaceName : undefined}
          navigation={navigation}
          deskAreas={deskAreas}
          initialDate={initialDate}
          location={location}
          fromFavoriteZone={fromFavoriteZone}
          parkingMode={parkingMode}
          visitorMode={visitorMode}
          visits={visits}
          zapfloorPois={zapfloorPois}
          selectedVisitor={selectedVisitor}
          visitorInfos={visitorInfos}
        />
      ),
      true
    )
  }

  const renderData = (deskArea: ZapfloorDeskArea) => (
    <DeskContainer onClick={() => selectDeskArea(deskArea)} key={deskArea.area.id}>
      <DeskTitle>{deskArea.area.name}</DeskTitle>
      <StatusContainer>
        <PointContainer />
        <Status>{i18n.t('screens.planning.deskSelectionModal.available')}</Status>
      </StatusContainer>
    </DeskContainer>
  )

  if (deskAreasAvailable.length === 0 && !isWaiting) {
    return (
      <MainContainer>
        <Title>{location.attributes.unit_name}</Title>
        <Label>{i18n.t(`screens.planning.deskSelectionModal.title${parkingMode ? 'Parking' : ''}`)}</Label>
        <PriorityContainer>
          <NoPriority visitorMode>
            {i18n.t(
              visitorMode
                ? 'screens.planning.errorMessages.notAvailable'
                : `screens.planning.deskSelectionModal.noDeskAvailable${parkingMode ? 'Parking' : ''}`
            )}
          </NoPriority>
        </PriorityContainer>
        {!visitorMode && (
          <ButtonContainer>
            <Button
              label={i18n.t('screens.planning.deskSelectionModal.continue')}
              onClick={() => selectDeskArea(undefined)}
              color={Theme.colors.blue}
              textColor={Theme.colors.white}
              shadow
              font={Theme.fonts.h3Bold}
            />
          </ButtonContainer>
        )}
        <ButtonContainer>
          <Button
            label={i18n.t('common.cancel')}
            onClick={closeModal}
            color={Theme.colors.white}
            textColor={Theme.colors.blue}
            shadow
            font={Theme.fonts.h3Bold}
          />
        </ButtonContainer>
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      <Title>{location.attributes.unit_name}</Title>
      <Label>{i18n.t(`screens.planning.deskSelectionModal.title${parkingMode ? 'Parking' : ''}`)}</Label>
      {isWaiting ? (
        <LoaderContainer>
          <Loader type="ThreeDots" color={Theme.colors.blue} height={20} />
        </LoaderContainer>
      ) : isNoPriority ? (
        <PriorityContainer>
          <NoPriority>
            {i18n.t(`screens.planning.deskSelectionModal.noPriority${parkingMode ? 'Parking' : ''}`)}
          </NoPriority>
        </PriorityContainer>
      ) : (
        <ListView>
          <LabelContainer>
            <Label>{i18n.t('screens.planning.deskSelectionModal.reservation')}</Label>
          </LabelContainer>
          {viewMore ? deskAreasAvailable.map((desk) => renderData(desk)) : firstData.map((desk) => renderData(desk))}
        </ListView>
      )}
      {deskAreas.length !== 0 && !isNoPriority ? (
        <>
          {deskAreasAvailable.length > 3 ? (
            <ButtonContainer>
              <ViewMoreButton onClick={() => setViewMore(!viewMore)}>
                <Label underline>
                  {!viewMore
                    ? i18n.t('screens.planning.deskSelectionModal.viewMore')
                    : i18n.t('screens.planning.deskSelectionModal.viewLess')}
                </Label>
              </ViewMoreButton>
            </ButtonContainer>
          ) : null}
          <ButtonContainer>
            <Button
              label={i18n.t('screens.planning.deskSelectionModal.plan')}
              onClick={chooseOnPlan}
              color={Theme.colors.blue}
              textColor={Theme.colors.white}
              shadow
              font={Theme.fonts.h3Bold}
            />
          </ButtonContainer>
        </>
      ) : (
        !isWaiting && (
          <ButtonContainer>
            <Button
              label={i18n.t('screens.planning.deskSelectionModal.continue')}
              onClick={() => selectDeskArea(undefined)}
              color={Theme.colors.blue}
              textColor={Theme.colors.white}
              shadow
              font={Theme.fonts.h3Bold}
            />
          </ButtonContainer>
        )
      )}
      <ButtonContainer>
        <Button
          label={i18n.t('common.cancel')}
          onClick={closeModal}
          color={Theme.colors.white}
          textColor={Theme.colors.blue}
          shadow
          font={Theme.fonts.h3Bold}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default DeskSelectionModal

// Containers style

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 400px;
`

const ListView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`
const DeskContainer = styled.button`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  &:hover {
    box-shadow: 0 0 20px 0 ${(props) => props.theme.colors.shadow};
  }
`

const LabelContainer = styled.div`
  padding-bottom: 10px;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 10px;
  justify-content: center;
`

const PriorityContainer = styled.div`
  display: flex;
  padding: 20px 0px;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`

//Text style

const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  line-height: 20px;
  margin: 0px 0px 20px;
  color: ${(props) => props.theme.colors.blue};
`

const DeskTitle = styled.p`
  margin: 0px;
  ${(props) => props.theme.fonts.bodyBold}
`
const Label = styled.p<{ underline?: boolean }>`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  ${(props) => props.underline && 'text-decoration: underline'};
`

const Status = styled.p`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.available};
  margin: 0px;
`

const NoPriority = styled.p<{ visitorMode?: boolean }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => (props.visitorMode ? props.theme.colors.red : props.theme.colors.orange)};
  margin: 0px;
`

//Other styles

const PointContainer = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin-right: 7px;
  background-color: ${(props) => props.theme.colors.available};
`

const ViewMoreButton = styled.button`
  align-items: center;
`
