import * as React from 'react'
import styled from 'theme/styled-components'
import Theme from 'theme/Theme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import sanitize from 'utils/Sanitizer'

import api from 'intervention/api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import Loader from 'react-loader-spinner'
import CategorySelector from 'intervention/components/CategorySelector'

interface Props {
  selectedPOI?: AreaV6
  setSelectedCategory: (c: CategoryV6) => void
  goBack?: () => void
}

const SEARCH = 1

export interface SubByMainCategory {
  placeTypesAssociated?: string[]
  categoryName: string
  categories: CategoryV6[]
}

const RequestTypeSelector = ({ setSelectedCategory, selectedPOI, goBack }: Props) => {
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const [categories, setCategories] = React.useState<SubByMainCategory[]>([])
  const [searchText, setSearchText] = React.useState('')
  const [researchStatus, setResearchStatus] = React.useState<'loading' | 'error' | 'ok'>('ok')

  const resultList: SubByMainCategory[] = React.useMemo(() => {
    if (searchText.length < SEARCH) {
      return categories
    }
    const sanitizeSearchText = sanitize(searchText)
    return categories
      .map((category) => {
        const filteredSubcategories = category.categories.filter(
          (subcategory) =>
            sanitize(subcategory.categoryName).includes(sanitizeSearchText) ||
            sanitize(subcategory.subcategoryName).includes(sanitizeSearchText)
        )

        return {
          ...category,
          categories: filteredSubcategories,
        }
      })
      .filter((category) => category.categories.length > 0)
  }, [categories, searchText])

  const suggestedList: SubByMainCategory[] = React.useMemo(() => {
    if (!selectedPOI) {
      return []
    }
    return resultList.filter(
      (category) =>
        !!category.placeTypesAssociated &&
        category.placeTypesAssociated.find(
          (placeType) => placeType.toLowerCase() === selectedPOI.placeType.toLowerCase()
        )
    )
  }, [selectedPOI, resultList])

  const otherCategories: SubByMainCategory[] = React.useMemo(
    () => resultList.filter((category) => !suggestedList.find((s) => s.categoryName === category.categoryName)),
    [resultList, suggestedList]
  )

  React.useEffect(() => {
    if (user && site) {
      setResearchStatus('loading')
      api
        .getParentCategories(site.id, user.type)
        .then((res) => {
          const grouped: { [key: string]: SubByMainCategory } = res.categories.reduce((acc, parentCategory) => {
            const categoryName = parentCategory.categoryName

            if (!acc[categoryName]) {
              acc[categoryName] = {
                placeTypesAssociated: parentCategory.placeTypesAssociated || [],
                categoryName: categoryName,
                categories: [],
              }
            }
            const filteredSubcategories = parentCategory.subcategories.filter(
              (sub) => sub.categoryId || sub.editorialPageIds.length > 0
            )

            acc[categoryName].categories.push(...filteredSubcategories)

            return acc
          }, {} as { [key: string]: SubByMainCategory })
          setCategories(Object.values(grouped).sort((a, b) => a.categoryName.localeCompare(b.categoryName)))
          setResearchStatus('ok')
        })
        .catch((err) => {
          setResearchStatus('error')
          Logger.error(err)
        })
    }
  }, [])

  return (
    <MainContainer>
      <TitleContainer>
        {!!goBack && (
          <CrossContainer
            onClick={() => {
              goBack()
              Modal.close()
            }}>
            <Icons name="chevron_left" size={25} color={Theme.colors.blue} />
          </CrossContainer>
        )}
        <Title>{i18n.t('screens.incident.whichCategories')}</Title>
        <CrossContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </CrossContainer>
      </TitleContainer>

      <SearchContainer>
        <SearchBarContainer>
          <SearchBar
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={i18n.t('screens.incident.search')}
            autoFocus
          />
          {searchText.length > 0 && (
            <CrossContainer
              onClick={() => {
                setSearchText('')
              }}>
              <Icons name="cross" size={17} color={Theme.colors.blue} />
            </CrossContainer>
          )}
          <IconContainer>
            <Icons name="search" size={15} color={Theme.colors.blue} />
          </IconContainer>
        </SearchBarContainer>
      </SearchContainer>

      <ListContainer>
        {researchStatus === 'loading' ? (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        ) : researchStatus === 'ok' ? (
          resultList.length > 0 ? (
            <CategoryListContainer>
              {suggestedList.length > 0 && (
                <>
                  <SuggestedListContainer>
                    <CategoryTitle>{i18n.t('screens.incident.suggestedCategories')}</CategoryTitle>
                    {suggestedList.map((res, i, categories) => (
                      <CategorySelector
                        key={res.categoryName}
                        category={res}
                        isLast={categories.length === i + 1}
                        setSelectedCategory={setSelectedCategory}
                      />
                    ))}
                  </SuggestedListContainer>
                </>
              )}
              {otherCategories.length > 0 && (
                <>
                  {suggestedList.length > 0 && (
                    <CategoryTitle>{i18n.t('screens.incident.allCategories')}</CategoryTitle>
                  )}

                  {otherCategories.map((res, i, categories) => (
                    <CategorySelector
                      key={res.categoryName}
                      category={res}
                      isLast={categories.length === i + 1}
                      setSelectedCategory={setSelectedCategory}
                    />
                  ))}
                </>
              )}
            </CategoryListContainer>
          ) : (
            <ErrorMessageContainer>
              <ErrorMessage>{i18n.t('errors.incident.noResult')}</ErrorMessage>
            </ErrorMessageContainer>
          )
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.incident.searchError')}</ErrorMessage>
          </ErrorMessageContainer>
        )}
      </ListContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 444px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
`
const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`
const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`
const SearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  flex: 1;
  margin: 0px 20px;
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 40px;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 30px;
  }
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
`
const CategoryListContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const CategoryTitle = styled('p')`
  margin: 0px;
  ${(props) => props.theme.fonts.body};
  padding-bottom: 16px;
`

const SuggestedListContainer = styled('div')`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

const SearchBar = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.label}
  margin-left: 24px;
  border: 0;
  outline: 0;
`

export default RequestTypeSelector
