import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as featureStore from 'sites/store/featureStore'
import api from 'sites/api/sitesApi'
import accountApi from 'account/accountApi'
import Logger from 'utils/Logger'

export let deeplinkLoading = false

const getScreenNameFromPageName = (page: string): ScreenName | undefined => {
  switch (page) {
    case 'home':
      return 'home'
    case 'news':
      return 'NEWS'
    case 'edito':
      return 'PRACTICAL_INFORMATION'
    case 'transport':
      return 'TRANSPORT'
    case 'map':
      return 'CARTOGRAPHY'
    case 'roombooking':
      return 'ROOM'
    case 'visit':
      return 'GUEST'
    case 'catering':
    case 'barclap':
      return 'CATERING'
    case 'incident':
      return 'INCIDENT'
    case 'account':
      return 'account'
    case 'cgu':
      return 'cgu'
    case 'cguPrivacyDetail':
      return 'cguDetails'
    case 'sites':
      return 'sites'
    case 'hotdesks':
      return 'PLANNING'
    case 'meetingcenter':
      return 'MEETING_CENTER'
    case 'mymrs':
      return 'myMRS'
    case 'poi':
      return 'poi'
    case 'boxIdeas':
      return 'BOX_IDEA'
    default:
      return undefined
  }
}

const extractFromQuery = (query: string) => {
  const searches = query.replace('?', '').split('&')
  return searches.reduce((acc, cur) => {
    const param = cur.split('=')
    acc[param[0]] = param[1]
    return acc
  }, {} as { [key: string]: string })
}

const queryToRoute = (query: string, screens: ScreenDefinition[], badgeSoldeIsNotNaN: boolean) => {
  const params = extractFromQuery(query)

  if (!params.page) {
    return '/'
  }

  const findSiteId = params.siteId
  const currentSite = siteStore.store.getState().site
  const currentUser = userStore.store.getState().user

  if (!!currentUser && !!findSiteId && !!currentSite && findSiteId !== currentSite.id) {
    // On va changer de site
    userStore.actions.setFullLoader(true)
    deeplinkLoading = true
    return api
      .getSite(findSiteId, currentUser.type)
      .then((res) => {
        siteStore.actions.setSite(res)
        accountApi
          .getUserFeatures(findSiteId, currentUser.type)
          .then((res) => featureStore.actions.setUserFeatures(res.features))
          .catch(Logger.error)
          .finally(() => {
            // On appelle quand même la méthode de gestion de deeplink en cas d'erreur
            const deeplink = handleDeeplink(params, screens, badgeSoldeIsNotNaN)
            window.location.href = deeplink
            userStore.actions.setFullLoader(false)
            deeplinkLoading = false
          })
      })
      .catch((err) => {
        Logger.error(err)
        // On appelle quand même la méthode de gestion de deeplink en cas d'erreur
        userStore.actions.setFullLoader(false)
        deeplinkLoading = false
        const deeplink = handleDeeplink(params, screens, badgeSoldeIsNotNaN)
        window.location.href = deeplink
      })
  } else {
    return handleDeeplink(params, screens, badgeSoldeIsNotNaN)
  }
}

const handleDeeplink = (
  params: { [key: string]: string },
  screens: ScreenDefinition[],
  badgeSoldeIsNotNaN: boolean
) => {
  const getScreenName = getScreenNameFromPageName(params.page)

  const src = !!getScreenName
    ? screens.find((s) => s.name.toLocaleLowerCase() === getScreenName.toLocaleLowerCase())
    : undefined

  if (!src) {
    return '/'
  }

  switch (params.page) {
    case 'news':
      if (!!params.id) {
        return `/news/${params.id}`
      }
      return src.path
    case 'edito':
      if (!!params.id) {
        return `/information/${params.id}`
      }
      return src.path
    case 'poi':
      if (!params.uniquePlaceName) {
        return '/'
      }
      if (!!params.mode) {
        return `/poi/${params.uniquePlaceName}/${params.mode}`
      }
      return `/poi/${params.uniquePlaceName}`
    case 'roombooking':
      if (!!params.id) {
        return `/room/${params.id}`
      }
      return src.path
    case 'catering':
    case 'barclap':
      if (!!params.id) {
        return `/catering/${params.id}`
      } else if (!!params.restaurantId) {
        const currentSite = siteStore.store.getState().site
        if (!currentSite || currentSite.id !== params.siteId) {
          return '/'
        }
        return `/catering/${params.restaurantId}/barclap`
      }
      return src.path
    case 'map':
      if (params.id) {
        return `/map?areaId=${params.id}`
      }
      return src.path
    case 'hotdesks':
      if (params.date) {
        return `/planning?date=${params.date}`
      }
      return src.path
    case 'mymrs':
      if (badgeSoldeIsNotNaN) {
        return `/mymrs${params.reload ? '?reload=true' : ''}`
      }
      return '/'
    case 'cguPrivacyDetail':
      if (params.mode && params.mode === 'cgu') {
        return '/cgu/cgu'
      }
      if (
        params.mode &&
        params.mode === 'privacy' &&
        params.userType &&
        (params.userType === 'internal' || params.userType === 'visitor')
      ) {
        if (params.siteId) {
          return `/cgu/privacy/${params.userType}/${params.siteId}`
        } else {
          return `/cgu/privacy/${params.userType}`
        }
      }
      return '/cgu/cgu'
    default:
      return src.path
  }
}

const checkOrigin = () => {
  const userAgent = navigator.userAgent
  if (!!userAgent.match(/iPhone/i)) {
    return 'iPhone'
  }
  if (!!userAgent.match(/Android/i)) {
    return 'android'
  }
  return 'other'
}

export { extractFromQuery, queryToRoute, checkOrigin }
