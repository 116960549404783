import React, { useState, useEffect } from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Modal from 'components/modal/Modal'
import UserList from '../components/UserList'
import PicturesList from '../components/PicturesList'
import ReservationTimeModal from '../myPlanning/ReservationTimeModal'
import Banner from 'components/banner/Banner'

import api from '../api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as planningStore from 'planning/planningStore'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'
import * as bannersStore from 'banners/store'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import utils from 'utils/strings'
import { getClosestDate, getMaxDate, getReservationTimes } from '../utils'

import Loader from 'react-loader-spinner'
import {
  isAfter,
  isWeekend,
  addBusinessDays,
  getDay,
  isBefore,
  isSameDay,
  differenceInDays,
  startOfDay,
} from 'date-fns'
import useI18n from 'i18n/useI18n'

type Status = 'loading' | 'success' | 'error' | 'notStarted'

const TIMEOPTIONS: ReservationTime[] = ['MORNING', 'AFTERNOON', 'ALL_DAY']
const TYPE_OPTIONS: ReservationType[] = ['DESK', 'PARKING']

const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

interface Props {
  setProfiles: (profiles: ZapfloorProfil[]) => void
  setSelectedTeam: (people?: ZapfloorProfil) => void
  profiles: ZapfloorProfil[]
  reservationDate: Date
  setReservationDate: (date: Date) => void
  selectedTeam?: ZapfloorProfil
  selectedUser?: UserResponse
  setSelectedUser: (user: UserResponse) => void
  selectedVisitor?: PersonalVisitV6
  setSelectedVisitor?: (visitor: PersonalVisitV6) => void
  datesToAvoid: Date[]
  setReservationType: (reservationType: ReservationType) => void
  setReservationTime: (reservationTime: ReservationTime) => void
  selectedReservationTime: ReservationTime
  setIsProfilesLoading: (isLoading: boolean) => void
  zapfloorPois?: AreaLightV6[]
  navigation: Navigation
  parkingMode: boolean
  visits?: PersonalVisitV6[]
  visitorStatus?: 'loading' | 'ok' | 'error'
  minDate?: Date
  maxDate?: Date
  disableVisitor: boolean
  reservationType: 'PARKING' | 'DESK'
}

const CURRENT_DATE = new Date()
const MAX_DAYS = 30

const MIN_DATE = !isWeekend(CURRENT_DATE) ? CURRENT_DATE : addBusinessDays(CURRENT_DATE, 1)

const ReservationForm = ({
  setProfiles,
  setSelectedTeam,
  profiles,
  reservationDate,
  setReservationDate,
  selectedUser,
  setSelectedUser,
  selectedTeam,
  datesToAvoid,
  setReservationTime,
  selectedReservationTime,
  setIsProfilesLoading,
  selectedVisitor,
  setSelectedVisitor,
  zapfloorPois,
  navigation,
  parkingMode,
  visits = [],
  visitorStatus,
  minDate,
  maxDate,
  disableVisitor,
  setReservationType,
  reservationType,
}: Props) => {
  const [Theme] = useTheme()
  const [status, setStatus] = useState<Status>('loading')
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
  const [isVisitorSelected, setIsVisitorSelected] = useState(false)
  const user = useReducer(userStore.store, (s) => s.user)
  const userZapfloorId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const features = useReducer(featureStore.store, (f) => f.features)
  const maxDaysInFuture = useReducer(planningStore.store, (s) => s.maxDaysInFuture)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const MAX_DATE = React.useMemo(
    () => getMaxDate(MIN_DATE, MAX_DAYS, maxDaysInFuture, currentSite?.maxDeskBookingDays),
    [MIN_DATE, currentSite, maxDaysInFuture]
  )

  const i18n = useI18n()

  const deskZapfloorVisitor = React.useMemo(
    () => !!features.find((feature) => feature.type === 'ZAPFLOOR_VISITOR_DESK'),
    [features]
  )
  const parkingZapfloorVisitor = React.useMemo(
    () => !!features.find((feature) => feature.type === 'ZAPFLOOR_VISITOR_PARKING'),
    [features]
  )

  const currentSiteHasZapfloorParking = React.useMemo(
    () => !!features.find((feature) => feature.type === 'ZAPFLOOR_PARKING'),
    [features]
  )

  const currentSiteHasVamosParking = React.useMemo(() => features.find((feature) => feature.type === 'VAMOS_PARKING'), [
    features,
  ])

  const showedBanner = React.useMemo(
    () =>
      banners.find(
        (banner) =>
          !!banner.feature &&
          ((banner.feature === 'ZAPFLOOR_VISITOR_DESK' && !parkingMode) ||
            ((banner.feature === 'ZAPFLOOR_VISITOR_PARKING' || banner.feature === 'ZAPFLOOR_PARKING') && parkingMode))
      ),
    [banners]
  )

  const parkingVisitorProfilId = currentSite?.zapfloorVisitorAccessGroupId

  const parkingVisitorUnitId = currentSite?.zapfloorVisitorZoneId

  const minSelectableDate = React.useMemo(
    () =>
      getClosestDate(
        minDate || MIN_DATE,
        maxDate || MAX_DATE,
        minDate || MIN_DATE,
        !selectedUser || (selectedUser && selectedUser.id === userZapfloorId) ? datesToAvoid : [],
        true
      ) ||
      minDate ||
      MIN_DATE,
    [datesToAvoid, selectedUser, userZapfloorId, minDate, maxDate]
  )

  useEffect(() => setIsProfilesLoading(status === 'loading'), [status])

  useEffect(() => {
    if (isAfter(minSelectableDate, reservationDate)) {
      setReservationDate(minSelectableDate)
    } else if (!!maxDate) {
      if (isBefore(maxDate, reservationDate)) {
        setReservationDate(maxDate)
      }
    }
  }, [minSelectableDate, reservationDate, maxDate])

  useEffect(() => {
    if (!selectedUser || !!selectedVisitor || selectedUser.id === userZapfloorId) {
      datesToAvoid.map((date) => {
        if (isSameDay(date, reservationDate)) {
          setReservationDate(minSelectableDate)
        }
      })
    }
  }, [minSelectableDate, datesToAvoid, reservationDate])

  useEffect(() => setSelectedTeam(profiles[0]), [profiles])

  useEffect(() => {
    if (!!user && !!userZapfloorId && !!currentSite && !!currentSite.locationId) {
      const reservationTimes = getReservationTimes(selectedReservationTime)
      setStatus('loading')
      api.zapfloor
        .getAvailableProfils(
          user.type,
          currentSite.id,
          (!!selectedUser && selectedUser.id) || userZapfloorId,
          i18n.t('formats.utcDate', { date: reservationDate }),
          currentSite.locationId!,
          reservationTimes.startTime,
          reservationTimes.endTime,
          parkingMode && selectedVisitor && !!parkingVisitorProfilId ? parkingVisitorProfilId : undefined
        )
        .then((res) => {
          const diffInDays = differenceInDays(reservationDate, startOfDay(CURRENT_DATE))
          const sortedTeams = res
            .filter((team) =>
              parkingMode
                ? team.attributes.description.toLowerCase().includes('parking')
                : !team.attributes.description.toLowerCase().includes('parking')
            )
            .filter((team) => team.attributes.max_days_in_future >= diffInDays)
            .sort((a, b) => b.attributes.priority - a.attributes.priority)
          setProfiles(sortedTeams)
          setStatus('success')
        })
        .catch(() => setStatus('error'))
    }
  }, [selectedUser, reservationDate, userZapfloorId, selectedReservationTime, selectedVisitor, reservationType])

  const searchUsers = (searchText: string, userType: UserType, siteId: string) =>
    api.zapfloor.getUsersSearch(userType, siteId, searchText)
  const searchFavorites = (favoriteUsersId: string, userType: UserType, siteId: string) =>
    api.zapfloor.getUsersList(userType, siteId, favoriteUsersId)

  const renderProfiles = () => {
    if (status === 'loading') {
      return (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      )
    }

    if (status === 'error') {
      return (
        <MainContainer>
          <Title>{i18n.t('screens.planning.errors.profiles')}</Title>
        </MainContainer>
      )
    }

    return profiles.length > 0 ? (
      profiles.map((profil, index) => {
        const { name, max_days, desks_booked_period, hot_desk_bookings, access_group_id } = profil.attributes
        const resaLeft = max_days - desks_booked_period

        return (
          <TeamContainer
            tabIndex={0}
            onClick={() => selectTeam(profil)}
            key={access_group_id}
            onKeyDown={(e) => onEnterKey(e, () => selectTeam(profil))}>
            <TeamInfoContainer>
              <TeamName>{name}</TeamName>
              <ReservationsLeft>
                {i18n.t('screens.planning.reservationsLeft', {
                  resaLeft: resaLeft < 0 ? 0 : resaLeft,
                  resaByWeek: max_days,
                })}
              </ReservationsLeft>
              {!!user && !parkingMode && (
                <PictureListContainer listLength={hot_desk_bookings.length > 4 ? 4 : hot_desk_bookings.length}>
                  <PicturesList
                    clickable
                    peopleList={hot_desk_bookings.map((user) => ({
                      id: user.user_id,
                      firstname: user.user_firstname,
                      lastname: user.user_lastname,
                      email: user.user_email,
                      timeFrom: user.time_from,
                      timeTo: user.time_to,
                      desk: zapfloorPois?.find((poi) => poi.zapfloorId === user.desk_id),
                    }))}
                    navigation={navigation}
                  />
                </PictureListContainer>
              )}
            </TeamInfoContainer>
            <TeamIconContainer>
              {(!!selectedTeam ? selectedTeam.attributes.access_group_id === access_group_id : index === 0) && (
                <Icons name="check-circle" color={Theme.colors.blue} size={26} />
              )}
            </TeamIconContainer>
          </TeamContainer>
        )
      })
    ) : (
      <NoProfilesContainer>
        <NoProfilesText>{i18n.t('screens.planning.firstForm.emptyProfiles')}</NoProfilesText>
      </NoProfilesContainer>
    )
  }

  const selectTeam = (profil: ZapfloorProfil) => {
    setSelectedTeam(profil)
  }

  const openTimeSlotSelector = () =>
    Modal.open(() => (
      <ReservationTimeModal
        title={i18n.t('screens.desk.time')}
        options={TIMEOPTIONS}
        setChoice={(newTime) => setReservationTime(newTime as ReservationTime)}
        typeModal="modal"
        type="time"
      />
    ))

  const openTypeSelector = () => {
    const canVisitorBookParking =
      !disableVisitor && parkingZapfloorVisitor && !!parkingVisitorProfilId && !!parkingVisitorUnitId
    return Modal.open(() => (
      <ReservationTimeModal
        title={i18n.t('screens.planning.firstForm.type')}
        options={(!!isVisitorSelected && !!canVisitorBookParking) || !isVisitorSelected ? TYPE_OPTIONS : ['DESK']}
        setChoice={(newType) => setReservationType(newType as ReservationType)}
        typeModal="modal"
        type="type"
      />
    ))
  }

  const filterWeekend = (date: Date) => ![0, 6].includes(getDay(date))

  return (
    <MainContainer>
      {!!showedBanner && <Banner banner={showedBanner} marginBottom={20} />}
      {currentSiteHasZapfloorParking && (
        <>
          <RowContainer>
            <Label>{i18n.t('screens.planning.firstForm.type')}</Label>
            <ChoiceContainer tabIndex={0} onClick={openTypeSelector}>
              <ChoiceText>{i18n.t(`screens.desk.reservationType.${parkingMode ? 'PARKING' : 'DESK'}`)}</ChoiceText>
              <IconContainer>
                <Icons name="chevron_right" color={Theme.colors.darkGrey} />
              </IconContainer>
            </ChoiceContainer>
          </RowContainer>
          <BlueLine role="presentation" />
        </>
      )}
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.for')}</Label>
        <ChoiceContainer
          tabIndex={0}
          onClick={() =>
            !!user &&
            !currentSiteHasVamosParking &&
            Modal.open(() => (
              <UserList
                setSelectedId={(user) => {
                  setSelectedUser(user)
                  setIsVisitorSelected(false)
                  Modal.close()
                }}
                searchUsers={searchUsers}
                title={i18n.t('screens.planning.firstForm.userListTitle')}
                searchFavorites={searchFavorites}
                closeModal={Modal.close}
                searchAriaLabel={i18n.t('label.ariaLabel.planning.searchContactForBooking')}
                navigation={navigation}
                withVisitors={
                  !disableVisitor &&
                  ((parkingMode && parkingZapfloorVisitor && !!parkingVisitorProfilId && !!parkingVisitorUnitId) ||
                    (!parkingMode && deskZapfloorVisitor))
                }
                setSelectedVisitor={(visitor) => {
                  if (!!setSelectedVisitor) {
                    Modal.close()
                    setSelectedVisitor(visitor)
                    setIsVisitorSelected(true)
                  }
                }}
                visitorStatus={visitorStatus}
                visits={visits}
                parkingMode={parkingMode}
                selectedVisitor={selectedVisitor}
              />
            ))
          }
          isDisabled={!!currentSiteHasVamosParking}>
          <ChoiceText isDisabled={!!currentSiteHasVamosParking}>
            {!!userZapfloorId &&
              (!!selectedVisitor
                ? `${selectedVisitor.visitorLastName.toUpperCase()} 
                ${utils.formatNames(selectedVisitor.visitorFirstName)}`
                : !selectedUser || selectedUser.id === userZapfloorId
                ? i18n.t('screens.planning.firstForm.placeHolderPeople')
                : `${selectedUser.attributes.lastname.toUpperCase()} 
              ${utils.formatNames(selectedUser.attributes.firstname)}`)}
          </ChoiceText>
          <IconContainer>
            <Icons
              name="chevron_right"
              color={!!currentSiteHasVamosParking ? Theme.colors.middleGrey : Theme.colors.darkGrey}
            />
          </IconContainer>
        </ChoiceContainer>
      </RowContainer>
      <BlueLine role="presentation" />
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.date')}</Label>
        <ChoiceContainer>
          <CliquableDiv
            tabIndex={0}
            onClick={() => setIsDatePickerVisible(true)}
            onKeyDown={(e) => onEnterKey(e, () => setIsDatePickerVisible(true))}>
            <ChoiceText>
              {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: reservationDate }))}
            </ChoiceText>
            <IconContainer>
              <Icons name="chevron_right" color={Theme.colors.darkGrey} />
            </IconContainer>
          </CliquableDiv>
          {isDatePickerVisible && (
            <CustomDatePicker
              selectedDate={reservationDate}
              onChange={setReservationDate}
              minDate={minDate || MIN_DATE}
              maxDate={maxDate || MAX_DATE}
              closePicker={() => setIsDatePickerVisible(false)}
              filterDate={filterWeekend}
              excludeDates={!selectedUser || selectedUser.id === userZapfloorId ? datesToAvoid : []}
            />
          )}
        </ChoiceContainer>
      </RowContainer>
      <BlueLine role="presentation" />
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.timeSlot')}</Label>
        <ChoiceContainer
          tabIndex={0}
          onClick={() => {
            if (currentSite?.halfDayDeskBooking) {
              openTimeSlotSelector()
            }
          }}
          isDisabled={!currentSite?.halfDayDeskBooking}>
          <ChoiceText isDisabled={!currentSite?.halfDayDeskBooking}>
            {i18n.t(`screens.desk.reservationTime.${selectedReservationTime}`)}
          </ChoiceText>
          <IconContainer>
            <Icons
              name="chevron_right"
              color={currentSite?.halfDayDeskBooking ? Theme.colors.darkGrey : Theme.colors.middleGrey}
            />
          </IconContainer>
        </ChoiceContainer>
      </RowContainer>
      <BlueLine role="presentation" />
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.profiles')}</Label>
      </RowContainer>
      <TeamsContainer>{renderProfiles()}</TeamsContainer>
    </MainContainer>
  )
}
export default ReservationForm

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  width: 350px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 300px;
  }
`

const PictureListContainer = styled.div<{ listLength: number }>`
  max-width: ${(props) => props.listLength * 32}px;
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label}
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex: 1;
`

const CliquableDiv = styled.div`
  cursor: pointer;
  display: flex;
`

const ChoiceContainer = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: flex-end;
  ${(props) => !props.isDisabled && 'cursor: pointer;'}
  flex: 3;
`

const ChoiceText = styled.p<{ isDisabled?: boolean }>`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => (props.isDisabled ? props.theme.colors.middleGrey : props.theme.colors.blue)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const IconContainer = styled.div``

const BlueLine = styled.div`
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`

const TeamsContainer = styled.div`
  margin-top: 10px;
`

const TeamContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
  min-height: 50px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  &:hover {
    box-shadow: 0 0 20px 0 ${(props) => props.theme.colors.shadow};
  }
`

const TeamName = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  margin: 0px;
`

const TeamInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`

const TeamIconContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`

const ReservationsLeft = styled.p`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
`

const NoProfilesContainer = styled.div`
  width: 100%;
  text-align: center;
`

const NoProfilesText = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 20px;
`
