import React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Icons from 'components/icons/Icons'

import Loader from 'react-loader-spinner'

interface Props {
  listElement: (room: AreaV6, i: number) => JSX.Element
  resultList: AreaV6[]
  setResultList: (r: AreaV6[]) => void
  searchText: string
  setSearchText: (s: string) => void
  researchStatus: Status
  setResearchStatus: (s: Status) => void
  searchPlaces: (searchText: string) => void
  height: string
  autoFocus?: boolean
  withoutSearchMinimum?: boolean
}

export type Status = 'notStarted' | 'error' | 'loading' | 'ready'

const PlaceSearch = ({
  listElement,
  resultList,
  setResultList,
  searchText,
  setSearchText,
  researchStatus,
  setResearchStatus,
  searchPlaces,
  height,
  autoFocus = false,
  withoutSearchMinimum,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  React.useEffect(() => {
    if (searchText.length < 3 && !withoutSearchMinimum) {
      setResearchStatus('notStarted')
      setResultList([])
    } else {
      const timer = setTimeout(() => searchPlaces(searchText), 500)
      return () => clearTimeout(timer)
    }
  }, [searchText])

  const resetSearchText = () => {
    setSearchText('')
    setResearchStatus('notStarted')
  }

  return (
    <>
      <SearchContainer>
        <SearchBarContainer>
          <SearchBar
            aria-label={i18n.t('label.ariaLabel.map.searchArea')}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={i18n.t('screens.cartography.search')}
            autoFocus={autoFocus}
          />
          {searchText.length > 0 && (
            <CrossContainer onClick={resetSearchText}>
              <Icons name="cross" size={17} color={Theme.colors.blue} />
            </CrossContainer>
          )}
          <IconContainer>
            <Icons name="search" size={15} color={Theme.colors.blue} />
          </IconContainer>
        </SearchBarContainer>
      </SearchContainer>
      <ListContainer height={height}>
        {researchStatus === 'loading' ? (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        ) : researchStatus === 'notStarted' ? (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.map.searchNotStarted')}</ErrorMessage>
          </ErrorMessageContainer>
        ) : researchStatus === 'ready' ? (
          resultList.length > 0 ? (
            resultList.map((r, i) => listElement(r, i))
          ) : (
            <ErrorMessageContainer>
              <ErrorMessage>{i18n.t('errors.map.noResult')}</ErrorMessage>
            </ErrorMessageContainer>
          )
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.map.searchError')}</ErrorMessage>
          </ErrorMessageContainer>
        )}
      </ListContainer>
    </>
  )
}

export default PlaceSearch

// CONTAINERS

const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`
const ListContainer = styled('div')<{ height: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: ${(props) => `${props.height}`};
  padding: 20px;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;
`
const SearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  width: 330px;
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
`
const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

// TEXTES

const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

// AUTRES

const SearchBar = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.label}
  margin-left: 24px;
  border: 0;
  outline: 0;
  element.focus({
  preventScroll: true
});
`
