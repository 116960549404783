import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Loader from 'react-loader-spinner'
import { isAfter, set } from 'date-fns'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Icons from 'components/icons/Icons'

interface Props {
  color?: string
  label?: string
  modifying?: boolean
  onClick?: () => void
  selected?: boolean
  iconName?: IconName
  iconSize?: number
  circleSize?: number
  deleteFunction?: (name: string) => void
  useFilter?: boolean
  thirdParty?: boolean
  useMargin?: boolean
  font?: Font
  ariaLabel?: string
  loading?: boolean
  margin?: number
  disabledTime?: string
}

const Shortcut = ({
  color,
  onClick,
  selected,
  iconName,
  circleSize = 90,
  iconSize = 45,
  modifying = false,
  label,
  deleteFunction = () => {
    null
  },
  useFilter = false,
  thirdParty = false,
  useMargin = true,
  font,
  ariaLabel,
  loading = false,
  margin,
  disabledTime,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const isShortcutDisabled = React.useMemo(() => {
    if (!disabledTime) {
      return false
    }
    const [hours, minutes] = disabledTime.split('h').map(Number)
    const now = new Date()
    const timeToCheck = set(now, { hours, minutes, seconds: 0, milliseconds: 0 })
    const isTimePassed = isAfter(now, timeToCheck)
    return isTimePassed
  }, [disabledTime])

  const onDelete = () => {
    if (label) {
      deleteFunction(label)
    }
  }

  return (
    <MainContainer
      margin={margin}
      useFilter={useFilter}
      useMargin={useMargin}
      onClick={() => {
        if (!loading && !!onClick && !isShortcutDisabled) {
          onClick()
        }
      }}
      aria-label={ariaLabel}>
      <Container
        size={circleSize}
        color={color || Theme.colors.turquoise}
        modifying={modifying}
        selected={selected}
        disabled={isShortcutDisabled}>
        {loading ? (
          <Loader type="TailSpin" color={Theme.colors.white} height={16} width={16} />
        ) : (
          !!iconName && <Icons size={iconSize} color={Theme.colors.white} name={iconName} />
        )}
      </Container>
      {!!label && (
        <Label modifying={modifying} newFont={font}>
          {thirdParty ? label : i18n.t(`screens.${label.toLowerCase()}.title`)}
        </Label>
      )}
      {!!modifying && (
        <DeleteIcon
          onClick={onDelete}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onDelete()
            }
          }}>
          <Icons name="cross" size={17} color={Theme.colors.blue} />
        </DeleteIcon>
      )}
    </MainContainer>
  )
}

const MainContainer = styled.button<{ useFilter: boolean; useMargin: boolean; margin?: number }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => (!!props.margin ? `margin: ${props.margin}px;` : !props.useFilter && props.useMargin && `margin: 20px`)};
  ${(props) => !props.useFilter && `width: 80px`};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    ${(props) => !props.useFilter && props.useMargin && `margin: 5px 20px`};
  }
`

const Container = styled.div<{
  selected?: boolean
  modifying?: boolean
  color: string
  size: number
  disabled?: boolean
}>`
  display: flex;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.size * 2}px;
  border: 0px;
  outline: 0px;
  ${(props) => !props.modifying && `cursor: pointer;&:hover{box-shadow: 5px 5px 10px ${props.theme.colors.shadow};}`}
  background-color: ${(props) => (props.disabled ? props.theme.colors.middleGrey : props.color)};
  :focus {
    outline: 3px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const DeleteIcon = styled.div`
  ${(props) => props.theme.fonts.labelBold}
  display: flex;
  position: absolute;
  top: -5px;
  right: -5px;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  outline: 0px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 5px 1px ${(props) => props.theme.colors.shadow};
  &:hover {
    box-shadow: 0px 0px 7px 2px ${(props) => props.theme.colors.shadow};
  }
  :focus {
    outline: 3px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const Label = styled.p<{ newFont?: Font; modifying?: boolean }>`
  flex: 1;
  ${(props) => (props.newFont ? props.newFont : props.theme.fonts.label)}
  margin-top: 8px;
  text-align: center;
  ${(props) => !props.modifying && `cursor: pointer;`}
`

export default Shortcut
