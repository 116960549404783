import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Loader from 'react-loader-spinner'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import useBreakpoint from 'utils/useBreakpoints'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import mapApi from 'map/mapApi'

import InteractiveToast from 'components/toast/InteractiveToast'

interface Props {
  uniquePlaceName: string
  mode?: 'ROOM' | 'INTERVENTION'
}

const PoiRedirectScreen = ({ uniquePlaceName, mode }: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const [Theme] = useTheme()
  const [, currentBreakpoint] = useBreakpoint()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  React.useEffect(() => {
    if (uniquePlaceName && site && user) {
      mapApi
        .fetchAreaReferentielWithUniqueName(site.id, uniquePlaceName, user.type)
        .then((res) => {
          const findPOI = res.areas.find((area) => area.uniquePlaceName === uniquePlaceName)
          if (!!findPOI) {
            if (!!mode) {
              if (mode === 'INTERVENTION') {
                // Aller sur la page demande d'intervention pré-rempli
                navigation.replace(`/incident`, {
                  room: findPOI,
                  origin: 'QR Code',
                })
              } else {
                // Aller sur la page détails d'une salle
                navigation.replace(`/room/${findPOI.id}`)
              }
            } else {
              const isMeetingRoom = findPOI.isMeetingRoom
              if (isMeetingRoom) {
                // Si c'est une salle de réu => aller sur la page détails d'une salle
                navigation.replace(`/room/${findPOI.id}`)
              } else {
                // Sinon aller sur la page demande d'intervention pré-rempli
                navigation.replace(`/incident`, {
                  room: findPOI,
                  origin: 'QR Code',
                })
              }
            }
          } else {
            displayErrorToast()
          }
        })
        .catch(displayErrorToast)
    }
  }, [])

  const displayErrorToast = () => {
    navigation.push('/')
    InteractiveToast.open(
      i18n.t('common.error'),
      i18n.t('screens.poi.error'),
      'cross-circle',
      Theme.colors.toastErrorText,
      Theme.colors.toastErrorBackground,
      currentBreakpoint === 'phone' ? 'bottom' : 'top-right',
      navigation
    )
  }

  return (
    <LoaderContainer>
      <Loader type="TailSpin" color={Theme.colors.blue} />
    </LoaderContainer>
  )
}

export default PoiRedirectScreen

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
`
