import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import RoomImage from 'components/image/RoomImage'
import { findLevelWording } from 'intervention/utils'

interface Props {
  onSet: (id: AreaV6) => void
  referentiel: AreaV6
  isLast: boolean
}

const RoomInfo = ({ onSet, referentiel, isLast }: Props) => {
  const i18n = useI18n()

  const goToDetails = () => onSet(referentiel)

  const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  return (
    <div>
      <ReferentielContainer onClick={goToDetails} tabIndex={0} onKeyDown={(e) => onEnterKey(e, goToDetails)}>
        <ReferentielImageContainer>
          <RoomImage iconSize={40} width={80} height={80} src={referentiel.picture} roomType={referentiel.placeType} />
        </ReferentielImageContainer>

        <InfosContainer>
          <ReferentielName>{referentiel.name}</ReferentielName>
          <ReferentielDescription>{referentiel.description}</ReferentielDescription>
          <ReferentielFloor>{findLevelWording(referentiel.floor, i18n, referentiel.floorCustomName)}</ReferentielFloor>
        </InfosContainer>
      </ReferentielContainer>
      {!isLast && <BlueLineBreak role="presentation" />}
    </div>
  )
}

// CONTAINERS

const ReferentielContainer = styled('div')`
  cursor: pointer;
  display: flex;
  height: 80px;
  width: 100%;
  margin: 12px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const InfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 13px 0px 0px 10px;
`
const ReferentielImageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
`

// TEXTES

const ReferentielName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  width: 230px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0px;
`
const ReferentielDescription = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.darkGrey};
  width: 230px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 2px 0px;
`
const ReferentielFloor = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.darkGrey};
  width: 230px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
`

export default RoomInfo
