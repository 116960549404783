import React from 'react'

import useI18n from 'i18n/useI18n'
import utils from 'utils/strings'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import {
  filteredDays,
  getDayOfWeek,
  findDay,
  findPriorityError,
  findAvailabilityColor,
  findAvailabilityIcon,
  findPercentageOccupation,
  findIconName,
  findColorOccupation,
  filterAvailableBookingsV2,
  getMessageError,
  updateBookingsStatusV2,
  getStatusErrorMessage,
  getReservationTimes,
  filterNotAvailableBookingsV2,
} from '../utils'
import analytics, { analyticsKeys, analyticsActionKeys } from 'utils/analytics'

import api from '../api'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as featureStore from 'sites/store/featureStore'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import SwitchFilter from 'components/switch/SwitchFilter'

import { compareAsc, isSameDay } from 'date-fns'
import Loader from 'react-loader-spinner'

type Status = 'loading' | 'success' | 'error' | 'notStarted'
declare interface PromiseInfo {
  api: Promise<ZapfloorZone[]>
  day: Date
  hotDeskId?: string
  slot?: 'MORNING' | 'AFTERNOON' | 'ALL_DAY'
}

declare interface SlotInfo {
  slot: 'MORNING' | 'AFTERNOON' | 'ALL_DAY'
  zone: ZapfloorZone
}

interface Props {
  currentWeekDays: Date[]
  referenceWeek: ReferenceWeek
  onClose: () => void
  onCancel: () => void
  userId: string
  userType: UserType
  zapfloorPois: AreaLightV6[]
}

const RecapReferenceWeek = ({
  currentWeekDays,
  referenceWeek,
  onCancel,
  userId,
  userType,
  onClose,
  zapfloorPois,
}: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const [isWaiting, setIsWaiting] = React.useState(false)
  const [isRandom, setIsRandom] = React.useState(false)
  const [zoneStatusScreen, setZoneStatusScreen] = React.useState<Status>('loading')
  const [availabilityZone, setAvailabilityZone] = React.useState<{ [key: string]: SlotInfo[] }>({})
  const [isBooking, setIsBooking] = React.useState(false)
  const [notAvailableBookings, setNotAvailableBookings] = React.useState<ZapfloorMultipleZoneRecurrence[]>([])

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const features = useReducer(featureStore.store, (f) => f.features)

  const currentSiteHasVamosParking = React.useMemo(() => features.find((feature) => feature.type === 'VAMOS_PARKING'), [
    features,
  ])

  const sortedAvailabilityReferenceWeek: ZapfloorMultipleZoneRecurrence[] = React.useMemo(() => {
    if (Object.keys(availabilityZone).length > 0) {
      const newBookingsInfo = Object.entries(availabilityZone)
        .map((data) => ({
          date: data[0],
          zones: data[1],
        }))
        .filter((data) => data.zones.length > 0)
        .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))

      const newBookingsMapped: ZapfloorMultipleZoneRecurrence[] = newBookingsInfo.map((infos) => ({
        date: infos.date,
        zones: infos.zones.map((zone) => zone.zone),
      }))
      const updatedBookingsStatus = updateBookingsStatusV2(newBookingsMapped as ZapfloorMultipleZoneRecurrence[])
      return updatedBookingsStatus
    }
    return []
  }, [availabilityZone])

  const availableBookings = React.useMemo(() => filterAvailableBookingsV2(sortedAvailabilityReferenceWeek, isRandom), [
    sortedAvailabilityReferenceWeek,
    isRandom,
  ])

  const availableBookingsLength = React.useMemo(
    () =>
      availableBookings.reduce((acc, cur) => {
        return acc + cur.zones.length
      }, 0),
    [availableBookings]
  )

  const filteredWeekDays = React.useMemo(() => filteredDays(currentWeekDays), [currentWeekDays])

  React.useEffect(() => {
    if (!isRandom) {
      setNotAvailableBookings(filterNotAvailableBookingsV2(sortedAvailabilityReferenceWeek))
    }
  }, [sortedAvailabilityReferenceWeek])

  React.useEffect(() => {
    fetchData()
  }, [zapfloorPois])

  React.useEffect(() => {
    if (Object.keys(availabilityZone).length === 0 && !isRandom) {
      setZoneStatusScreen('loading')
      fetchData()
    }
  }, [availabilityZone])

  React.useEffect(() => {
    if (!!isRandom) {
      const promises: PromiseInfo[] = []
      if (notAvailableBookings.length > 0) {
        setIsWaiting(true)
        setZoneStatusScreen('loading')
        notAvailableBookings.map((booking) => {
          const day = new Date(booking.date)
          const getDay = getDayOfWeek(day)
          const favoriteZoneInfos = findDay(getDay.toUpperCase(), referenceWeek).filter(
            (info) => !!info.profilId && !!info.reservationTime && !!info.zoneId
          )
          favoriteZoneInfos.map((zone) => {
            const { profilId, zoneId, reservationTime, hotDeskId } = zone
            const findPOI = zapfloorPois.find((poi) => poi.zapfloorId === hotDeskId)
            if (!!reservationTime && !!profilId && !!zoneId && !!currentSite && !!currentSite.locationId) {
              if (!!findPOI) {
                promises.push({
                  api: api.zapfloor.getHotDeskAvailable(
                    userType,
                    currentSite.id,
                    i18n.t('formats.utcDate', {
                      date: day,
                    }),
                    currentSite.locationId,
                    reservationTime,
                    profilId,
                    userId,
                    zoneId
                  ),
                  day,
                  hotDeskId,
                  slot: reservationTime,
                })
              }
            }
          })

          Promise.all(promises.map((p) => p.api))
            .then((res) => {
              const newAvailabilities = { ...availabilityZone }
              res.map((r, index) => {
                const findAvailabilitiesIndex = newAvailabilities[promises[index].day.toString()].findIndex(
                  (a) => a.slot === promises[index].slot
                )
                const findDesk = r.find(
                  (desk) =>
                    !desk.attributes.hot_desk_in_use &&
                    zapfloorPois.find((poi) => poi.zapfloorId === desk.attributes.hot_desk_id)
                )
                if (!!findDesk && findAvailabilitiesIndex > -1) {
                  newAvailabilities[promises[index].day.toString()][findAvailabilitiesIndex] = {
                    slot: promises[index].slot!,
                    zone: findDesk,
                  }
                }
              })
              setZoneStatusScreen('success')
              setAvailabilityZone(newAvailabilities)
            })
            .catch(() => setZoneStatusScreen('error'))
            .finally(() => setIsWaiting(false))
        })
      }
    } else if (!isRandom) {
      setAvailabilityZone({})
    }
  }, [isRandom])

  const fetchData = () => {
    if (zapfloorPois.length > 0) {
      const promises: PromiseInfo[] = []
      filteredWeekDays.map((day) => {
        const getDay = getDayOfWeek(day)
        const favoriteZoneInfos = findDay(getDay.toUpperCase(), referenceWeek).filter(
          (info) => !!info.profilId && !!info.reservationTime && !!info.zoneId
        )

        favoriteZoneInfos.map((zone) => {
          const { profilId, zoneId, reservationTime, hotDeskId } = zone
          const getSlotTimes = getReservationTimes(reservationTime!)
          const findPOI = zapfloorPois.find((poi) => poi.zapfloorId === hotDeskId)
          if (!!reservationTime && !!profilId && !!zoneId && !!currentSite && !!currentSite.locationId) {
            if (!!findPOI) {
              promises.push({
                api: api.zapfloor.getHotDeskAvailable(
                  userType,
                  currentSite.id,
                  i18n.t('formats.utcDate', { date: day }),
                  currentSite.locationId,
                  reservationTime,
                  profilId,
                  userId,
                  zoneId
                ),
                day,
                hotDeskId,
                slot: reservationTime,
              })
            } else {
              promises.push({
                api: api.zapfloor.getAvailableZones(
                  userType,
                  currentSite!.id,
                  i18n.t('formats.utcDate', { date: day }),
                  profilId!,
                  currentSite!.locationId!,
                  getSlotTimes.startTime,
                  getSlotTimes.endTime,
                  userId,
                  zoneId
                ),
                day,
                slot: reservationTime,
              })
            }
          }
        })
      })

      Promise.all(promises.map((p) => p.api))
        .then((res) => {
          const newAvailabilities = { ...availabilityZone }
          res.map((r, index) => {
            const findPromise = promises[index]
            const findDesk = r.find((desk) => desk.attributes.hot_desk_id === findPromise.hotDeskId)

            const test = !!newAvailabilities[promises[index].day.toString()]
              ? [
                  ...newAvailabilities[promises[index].day.toString()],
                  { slot: promises[index].slot!, zone: !!findDesk ? findDesk : r[0] },
                ]
              : [{ slot: promises[index].slot!, zone: !!findDesk ? findDesk : r[0] }]
            newAvailabilities[promises[index].day.toString()] = test
          })
          setZoneStatusScreen('success')
          setAvailabilityZone(newAvailabilities)
        })
        .catch(() => setZoneStatusScreen('error'))
    }
  }

  const onClickBooking = () => {
    if (!!currentSite && !!currentSite.locationId) {
      analytics.track({
        screenName: analyticsKeys.zapfloor,
        userType: user?.type,
        currentSite,
        mySites,
        action: analyticsActionKeys.planningApplyRefWeek,
      })

      setIsBooking(true)
      const promises: Promise<ZapfloorBookingResult>[] = []
      const vamosPromises: Promise<void>[] = []
      availableBookings.map((booking) => {
        const deskBookings = booking.zones.filter(
          (zone) => !zone.attributes.description.toLowerCase().includes('parking')
        )
        const parkingBookings = booking.zones.filter((zone) =>
          zone.attributes.description.toLowerCase().includes('parking')
        )
        deskBookings.map((zone, index) => {
          if (currentSiteHasVamosParking) {
            vamosPromises.push(
              api.vamos.vamosAccess(userType, currentSite.id, {
                accessDay: i18n.t('screens.planning.secondForm.dateToSend', {
                  date: new Date(booking.date),
                }),
              })
            )
          }

          const errorMessage = findPriorityError(zone.attributes.error_response)
          const statusErrorMessage = getStatusErrorMessage(errorMessage)
          const getDayString = getDayOfWeek(new Date(booking.date))
          const findReferenceWeekDay = findDay(getDayString.toUpperCase(), referenceWeek)
          const findZoneInfos = findReferenceWeekDay.filter(
            (d) => d.profilId === zone.attributes.access_group_id && d.zoneId === zone.attributes.unit_id
          )
          const findSlotZoneInfos = findZoneInfos.length > 1 ? findZoneInfos[index] : findZoneInfos[0]
          const getSlotTimes = getReservationTimes(findSlotZoneInfos.reservationTime!)
          const findPOI = zapfloorPois.find((poi) => poi.zapfloorId === zone.attributes.hot_desk_id)
          const request: BodyBookingHotDesking = {
            reservation: {
              date_from: i18n.t('formats.utcDate', { date: new Date(booking.date) }),
              time_from: getSlotTimes.startTime,
              time_to: getSlotTimes.endTime,
              location_id: currentSite.locationId!,
              waiting_list: statusErrorMessage === 'waiting',
              description: '',
              create_type: 1,
            },
            booked_for_users: [userId],
            unit_ids: !!findPOI && statusErrorMessage === 'ok' ? [] : [zone.attributes.unit_id],
            access_group_ids: [zone.attributes.access_group_id],
            hot_desks: !!findPOI && statusErrorMessage === 'ok' ? [zone.attributes.hot_desk_id] : [],
          }
          promises.push(api.zapfloor.bookHotDesk(userType, currentSite.id, request))
        })
        parkingBookings.map((zone, index) => {
          const errorMessage = findPriorityError(zone.attributes.error_response)
          const statusErrorMessage = getStatusErrorMessage(errorMessage)
          const getDayString = getDayOfWeek(new Date(booking.date))
          const findReferenceWeekDay = findDay(getDayString.toUpperCase(), referenceWeek)
          const findZoneInfos = findReferenceWeekDay.filter(
            (d) => d.profilId === zone.attributes.access_group_id && d.zoneId === zone.attributes.unit_id
          )
          const findSlotZoneInfos = findZoneInfos.length > 1 ? findZoneInfos[index] : findZoneInfos[0]
          const getSlotTimes = getReservationTimes(findSlotZoneInfos.reservationTime!)
          const findPOI = zapfloorPois.find((poi) => poi.zapfloorId === zone.attributes.hot_desk_id)
          const request: BodyBookingHotDesking = {
            reservation: {
              date_from: i18n.t('formats.utcDate', { date: new Date(booking.date) }),
              time_from: getSlotTimes.startTime,
              time_to: getSlotTimes.endTime,
              location_id: currentSite.locationId!,
              waiting_list: statusErrorMessage === 'waiting',
              description: 'Parking',
              create_type: 1,
            },
            booked_for_users: [userId],
            unit_ids: !!findPOI && statusErrorMessage === 'ok' ? [] : [zone.attributes.unit_id],
            access_group_ids: [zone.attributes.access_group_id],
            hot_desks: !!findPOI && statusErrorMessage === 'ok' ? [zone.attributes.hot_desk_id] : [],
          }
          promises.push(api.zapfloor.bookHotDesk(userType, currentSite.id, request))
        })
      })
      Promise.all(vamosPromises)
        .then(() =>
          Promise.all(promises)
            .then(onClose)
            .catch(() =>
              Alert.open(
                () => (
                  <ValidationContent
                    title={i18n.t('screens.planning.referenceWeek.errorBooking')}
                    onConfirm={Alert.close}
                    ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                  />
                ),
                true
              )
            )
            .finally(() => setIsBooking(false))
        )
        .catch((err) => {
          setIsBooking(false)
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.referenceWeek.errorBooking')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                description={
                  err.data.error
                    ? i18n.t('screens.planning.parking.genericError', { errorCode: err.data.error })
                    : undefined
                }
              />
            ),
            true
          )
        })
    }
  }

  const renderZoneInfos = (bookingZone: ZapfloorZone, zoneInfos: FavoriteZone, borderBottom: boolean, day: Date) => {
    const findMessageError = findPriorityError(bookingZone.attributes.error_response)
    const findPercentage = findPercentageOccupation(bookingZone.attributes)
    const findIconOccupancy = findIconName(findPercentage)
    const findColorOccupancy = findColorOccupation(findPercentage, theme)
    const findInitialBooking = notAvailableBookings.find((booking) => isSameDay(day, new Date(booking.date)))
    const findPOI = zapfloorPois.find((poi) => poi.zapfloorId === bookingZone.attributes.hot_desk_id)
    const isHotDeskInUse = !!findPOI && !isRandom ? bookingZone.attributes.hot_desk_in_use : undefined
    const findColorAvailability = findAvailabilityColor(findMessageError, theme, isHotDeskInUse)
    const findIconAvailability = findAvailabilityIcon(findMessageError, isHotDeskInUse)

    //check if a desk has been replaced by the randomDesk function
    const isRandomDesk =
      !!findPOI &&
      !!findInitialBooking &&
      findInitialBooking.zones.find((desk) => desk.attributes.hot_desk_id !== findPOI.zapfloorId)

    if (!zoneInfos) {
      return null
    }

    return (
      <ZoneInfosMainContainer borderBottom={borderBottom}>
        <RowContainer>
          <ColumnContainer>
            {zoneInfos.reservationTime !== 'ALL_DAY' && (
              <InfosContainer>
                <IconContainer>
                  <Icon name="clock" color={theme.colors.blue} size={20} />
                </IconContainer>
                <Label>{i18n.t(`screens.desk.reservationTime.${zoneInfos.reservationTime}`)}</Label>
              </InfosContainer>
            )}
            <InfosContainer>
              <IconContainer>
                <Icon
                  name="id_card"
                  color={theme.colors.blue}
                  size={20}
                  ariaLabel={i18n.t('screens.planning.iconsAction.team')}
                />
              </IconContainer>
              <Label>{bookingZone.attributes.access_group_name}</Label>
            </InfosContainer>
            <InfosContainer>
              <IconContainer>
                <Icon
                  name={zoneInfos.isParking ? 'parking' : 'desk'}
                  color={theme.colors.blue}
                  size={20}
                  ariaLabel={i18n.t('screens.planning.iconsAction.zone')}
                />
              </IconContainer>
              <Label>{!!zoneInfos.hotDeskName ? zoneInfos.hotDeskName : bookingZone.attributes.unit_name}</Label>
            </InfosContainer>
          </ColumnContainer>

          <OccupancyContainer>
            {!zoneInfos.hotDeskId && (
              <>
                <IconContainer>
                  <Icon
                    name={findIconOccupancy}
                    color={findColorOccupancy}
                    size={16}
                    ariaLabel={i18n.t('screens.planning.iconsAction.occupancyRate')}
                  />
                </IconContainer>

                <OccupancyRate occupancyColor={findColorOccupancy}>{findPercentage}%</OccupancyRate>
              </>
            )}
            <IconContainer>
              <Icon name={findIconAvailability} size={27} color={findColorAvailability} />
            </IconContainer>
          </OccupancyContainer>
        </RowContainer>
        {!(findMessageError === 'zone_is_full_booked' && !!findPOI && !isRandom) && findMessageError !== 'ok' ? (
          <MessageContainer>
            <Message color={findColorAvailability}>
              {i18n.t(
                findMessageError === 'zone_is_full_booked' && isRandom
                  ? 'screens.planning.modal.noOtherDeskAvailable'
                  : getMessageError(findMessageError, true)
              )}
            </Message>
          </MessageContainer>
        ) : !!findPOI && isHotDeskInUse ? (
          <MessageContainer>
            <Message color={findColorAvailability}>{i18n.t('screens.planning.modal.unavailableDesk')}</Message>
          </MessageContainer>
        ) : !!findPOI && !isHotDeskInUse && isRandom && isRandomDesk && !isWaiting ? (
          <MessageContainer>
            <Message color={findColorAvailability}>
              {i18n.t('screens.planning.modal.randomDesk', {
                deskName: findPOI.name,
              })}
            </Message>
          </MessageContainer>
        ) : null}
      </ZoneInfosMainContainer>
    )
  }

  const renderDay = (day: Date) => {
    const findBookings = sortedAvailabilityReferenceWeek.find((booking) => isSameDay(new Date(booking.date), day))
    const getDayString = getDayOfWeek(day)
    const findReferenceWeekDay = findDay(getDayString.toUpperCase(), referenceWeek)

    if (!findBookings) {
      return (
        <DayContainer>
          <DayHeader>
            <DayTitle>{utils.capitalize(i18n.t('screens.planning.reservationDate', { date: day }))}</DayTitle>
          </DayHeader>
          <InfosMainContainer>
            <Label>{i18n.t(`screens.planning.referenceWeek.noBooking`)}</Label>
          </InfosMainContainer>
        </DayContainer>
      )
    }

    const filterParkingBookings = findBookings.zones.filter((zone) =>
      zone.attributes.description.toLowerCase().includes('parking')
    )
    const filterDeskNookings = findBookings.zones.filter(
      (zone) => !zone.attributes.description.toLowerCase().includes('parking')
    )

    return (
      <DayContainer>
        <DayHeader>
          <DayTitle>{utils.capitalize(i18n.t('screens.planning.reservationDate', { date: day }))}</DayTitle>
        </DayHeader>

        {filterDeskNookings.map((zone, index) => {
          const findZoneInfos = findReferenceWeekDay.filter(
            (d) => d.profilId === zone.attributes.access_group_id && d.zoneId === zone.attributes.unit_id
          )
          return findZoneInfos.length > 0
            ? renderZoneInfos(
                zone,
                findZoneInfos.length > 1 ? findZoneInfos[index] : findZoneInfos[0],
                index < findBookings.zones.length - 1,
                day
              )
            : null
        })}

        {filterParkingBookings.map((zone, index) => {
          const findZoneInfos = findReferenceWeekDay.filter(
            (d) => d.profilId === zone.attributes.access_group_id && d.zoneId === zone.attributes.unit_id
          )
          return findZoneInfos.length > 0
            ? renderZoneInfos(
                zone,
                findZoneInfos.length > 1 ? findZoneInfos[index] : findZoneInfos[0],
                index === 0 && filterParkingBookings.length > 1,
                day
              )
            : null
        })}
      </DayContainer>
    )
  }

  const renderRandomDesk = () => {
    if (notAvailableBookings.length > 0 && zoneStatusScreen !== 'loading') {
      return (
        <RandomDeskContainer>
          <BlueLine role="presentation" />
          <RowContainer>
            <div>
              <IconContainer>
                <Icon name="warning" color={theme.colors.orange} size={30} />
              </IconContainer>
            </div>
            <Warning>{i18n.t('screens.planning.errorMessages.warningDeskUnavailabilityReferenceWeek')}</Warning>
          </RowContainer>
          <RowContainer>
            <div>
              <Label>{i18n.t('screens.planning.errorMessages.randomDesk')}</Label>
            </div>
            <SwitchFilter value={isRandom} setValue={setIsRandom} />
          </RowContainer>
        </RandomDeskContainer>
      )
    }
  }

  return (
    <ModalContainer>
      <Title>{i18n.t('screens.planning.referenceWeek.recap')}</Title>
      {zoneStatusScreen === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={theme.colors.blue} />
        </LoaderContainer>
      ) : zoneStatusScreen === 'error' ? (
        <Error>{i18n.t('screens.planning.referenceWeek.errorAvailability')}</Error>
      ) : (
        <>
          {filteredWeekDays.map((day) => renderDay(day))}
          {renderRandomDesk()}
          <ButtonsContainer>
            <ApplyContainer>
              <Button
                shadow
                font={theme.fonts.h3Bold}
                onClick={onClickBooking}
                label={i18n.t('screens.planning.modal.confirmReservation', {
                  count: availableBookingsLength,
                })}
                disabled={availableBookingsLength === 0}
                loading={isBooking}
              />
            </ApplyContainer>

            <Button
              font={theme.fonts.h3Bold}
              onClick={onCancel}
              label={i18n.t('common.cancel')}
              textColor={theme.colors.blue}
              color={theme.colors.white}
              shadow
            />
          </ButtonsContainer>
        </>
      )}
    </ModalContainer>
  )
}

export default RecapReferenceWeek

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 280px;
    padding: 15px;
  }
`

const Title = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  align-self: flex-start;
  margin: 0 0 20px 0;
`

const DayContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  margin: 10px 0px;
  flex-direction: column;
  width: 100%;
`

const DayHeader = styled.div`
  padding-left: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

const InfosMainContainer = styled.div`
  padding: 12px;
`

const DayTitle = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
`

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  padding-right: 3px;
`

const Label = styled.p`
  padding-left: 6px;
  ${(props) => props.theme.fonts.label};
  margin: 6px 0;
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`

const ApplyContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const Error = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 20px;
  margin: 6px 0px;
`

const OccupancyRate = styled.p<{ occupancyColor: string }>`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.occupancyColor};
  padding: 3px 8px 0px 2px;
`

const Message = styled('p')<{ color: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.color};
  margin: 0px 0px 12px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`

const ZoneInfosMainContainer = styled.div<{ borderBottom: boolean }>`
  ${(props) => props.borderBottom && ` border-bottom: 1px solid ${props.theme.colors.mediumDarkGrey};`}
`

const OccupancyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MessageContainer = styled.div`
  padding: 0px 12px;
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const RandomDeskContainer = styled.div`
  padding-top: 30px;
`
const BlueLine = styled.div`
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`
const Warning = styled.p`
  ${(props) => props.theme.fonts.subtitle}
  color: ${(props) => props.theme.colors.orange};
`
