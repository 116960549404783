import * as React from 'react'
import Loader from 'react-loader-spinner'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import config from 'core/src/config'

import useI18n from 'i18n/useI18n'

import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { checkOrigin } from 'core/src/layout/utils'
import analytics from 'utils/analytics'

import Button from 'components/button/Button'
import Form from 'components/form/AuthentForm'
import Icons from 'components/icons/Icons'
import TitleHelmet from 'components/title/TitleHelmet'
import Modal from 'components/modal/Modal'
import LanguageChoice, { LANG_LIST } from './LanguageChoice'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import InstallAppModal from 'components/modal/InstallAppModal'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as store from 'store/store'
import * as languageStore from 'store/lang/languageStore'

import accountApi from 'account/accountApi'
import msal from '../azure/MSALAuth'
import equansMsal from '../azure/MSALEquans'

const LoginScreen = () => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()
  const path = navigation.getLocation().pathname
  const currentLanguage = useReducer(languageStore.store, (s) => s.preferredLanguage)

  const seeDownloadApp = useReducer(userStore.store, (s) => s.seeDownloadApp)

  const [externalUserType, setExternalUserType] = React.useState(false)

  const [loginStatus, setLoginStatus] = React.useState(true)
  const [loaded, setLoaded] = React.useState(false)

  const fullClear = () => {
    store.reset()
    new Array(localStorage.length).fill(0).forEach((x, idx) => {
      const key = localStorage.key(idx)
      if (key && !key.startsWith('persist')) {
        localStorage.removeItem(key)
      }
    })
    new Array(sessionStorage.length).fill(0).forEach((x, idx) => {
      const key = sessionStorage.key(idx)
      if (key && key.includes('interaction.status')) {
        sessionStorage.removeItem(key)
      }
    })
  }

  React.useEffect(() => {
    openInstallAppPopUp()
    // On ne cherche à récupérer les données utilisateurs
    // que lors de la redirection depuis la mire Office
    store
      .getCurrentStorageNecessary()
      .getItem('AUTH_TYPE')
      .then((authType) => {
        if (authType === 'AAD') {
          return msal.me().then((user) => {
            accountApi
              .getInfo(user.type)
              .then((u) => {
                if (user.id || user.email) {
                  analytics.identify((user.id || user.email) as string)
                }
                userStore.actions.setUser({ ...user, officeId: u.id })
              })
              .catch(() => {
                Logger.error()
                Alert.open(
                  () => <ValidationContent title={i18n.t('screens.login.error')} onConfirm={Alert.close} />,
                  true
                )
              })
          })
        } else if (authType === 'EQUANS') {
          return equansMsal.me().then((user) => {
            accountApi
              .getInfo(user.type)
              .then((u) => {
                if (user.id || user.email) {
                  analytics.identify((user.id || user.email) as string)
                }
                userStore.actions.setUser({ ...user, officeId: u.id })
              })
              .catch(Logger.error)
          })
        } else {
          setLoaded(true)
        }
      })
      .catch(Logger.error)
      .finally(() => setLoaded(true))
  }, [])

  const openInstallAppPopUp = () => {
    if (!seeDownloadApp) {
      const origin = checkOrigin()
      if (origin === 'iPhone' || origin === 'android') {
        userStore.actions.setSeeDownloadApp(true)
        Modal.open(() => <InstallAppModal origin={origin} />, undefined, undefined, 16)
      }
    }
  }

  const loginOffice = () => {
    fullClear()
    store
      .getCurrentStorageNecessary()
      .setItem('AUTH_TYPE', 'AAD')
      .then(() => msal.login('server'))
      .catch(Logger.error)
  }

  const loginEquans = () => {
    fullClear()
    store
      .getCurrentStorageNecessary()
      .setItem('AUTH_TYPE', 'EQUANS')
      .then(() => equansMsal.login('server'))
      .catch(Logger.error)
  }

  const loginGuest = () => {
    fullClear()
    store
      .getCurrentStorageNecessary()
      .setItem('AUTH_TYPE', 'GUEST')
      .then(() => setLoginStatus(false))
      .catch(Logger.error)
  }

  const goToCGU = () => navigation.push('/cgu')

  const renderChooseType = () =>
    !externalUserType ? (
      <ButtonMainContainer>
        <ButtonContainer>
          <Button
            label={i18n.t('screens.login.officeLogin')}
            onClick={loginOffice}
            color={Theme.colors.blue}
            textColor={Theme.colors.white}
            font={Theme.fonts.h1}
            fontSize={18}
            ariaLabel={i18n.t('label.ariaLabel.login.engieLogin')}
          />
        </ButtonContainer>
        <ButtonContainer>
          <Button
            label={i18n.t('screens.login.external')}
            onClick={() => setExternalUserType(true)}
            color={Theme.colors.white}
            textColor={Theme.colors.blue}
            font={Theme.fonts.h1}
            fontSize={18}
            ariaLabel={i18n.t('label.ariaLabel.login.external')}
          />
        </ButtonContainer>
      </ButtonMainContainer>
    ) : (
      <ButtonMainContainer>
        <ButtonContainer>
          <Button
            label={i18n.t('screens.login.equansLogin')}
            onClick={loginEquans}
            font={Theme.fonts.h1}
            fontSize={18}
            ariaLabel={i18n.t('label.ariaLabel.login.equansLogin')}
          />
        </ButtonContainer>
        <ButtonContainer>
          <Button
            label={i18n.t('screens.login.guestLogin')}
            onClick={loginGuest}
            color={Theme.colors.white}
            textColor={Theme.colors.blue}
            font={Theme.fonts.h1}
            fontSize={18}
            ariaLabel={i18n.t('label.ariaLabel.login.guestLogin')}
          />
        </ButtonContainer>
        <Link
          onClick={() => setExternalUserType(false)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setExternalUserType(false)
            }
          }}>
          {i18n.t('screens.login.goBackToUserType')}
        </Link>
      </ButtonMainContainer>
    )

  return (
    <div>
      <TitleHelmet title={i18n.t('screens.login.title')} />
      <BackgroundImage src={require('assets/background-login.jpg').default} alt="" />

      <LoginContainer>
        <MyPortalIconContainer>
          <Icons name="logo" size={275} color={Theme.colors.white} />
        </MyPortalIconContainer>
        {loaded ? (
          <>
            {loginStatus ? (
              <TextContainer>
                <WelcomeText>{i18n.t('screens.login.welcome')}</WelcomeText>
                <BodyText>{i18n.t(`screens.login.iam${externalUserType ? '_external' : ''}`)}</BodyText>
                {renderChooseType()}
                <Link
                  onClick={goToCGU}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      goToCGU()
                    }
                  }}>
                  {i18n.t('screens.cgu.title')}
                </Link>

                <LanguageContainer>
                  <CurrentLanguage>
                    {i18n.t('screens.login.currentLang')}
                    {!!currentLanguage && LANG_LIST[currentLanguage]}
                  </CurrentLanguage>

                  <Link
                    onClick={() => Modal.open(() => <LanguageChoice navigation={navigation} path={path} />)}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        Modal.open(() => <LanguageChoice navigation={navigation} path={path} />)
                      }
                    }}>
                    {i18n.t('screens.login.modifyLang')}
                  </Link>
                </LanguageContainer>

                <AppVersion>{i18n.t('common.version', { version: config.appVersion })}</AppVersion>
              </TextContainer>
            ) : (
              <TextContainer>
                <Form />
              </TextContainer>
            )}
          </>
        ) : (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        )}
      </LoginContainer>
    </div>
  )
}

export default LoginScreen

const LoginContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`

const BackgroundImage = styled.img`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1%;
  width: 450px;
  padding: 20px 0px;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  margin-bottom: 5%;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 98%;
  }
`
const Link = styled('a')`
  cursor: pointer;
  flex: 1;
  ${(props) => props.theme.fonts.body};
  font-size: 15px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
  padding: 5px;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
    text-decoration: none;
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
`
const ButtonMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 280px;
  margin: 10px;
`
const WelcomeText = styled('p')`
  flex: 1;
  ${(props) => props.theme.fonts.h1}
  color: ${(props) => props.theme.colors.loginWelcome};
  margin-bottom: 60px;
  margin-top: 40px;
`
const BodyText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 18px;
  color: ${(props) => props.theme.colors.white};
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
`
const MyPortalIconContainer = styled('div')`
  margin-top: 2%;
`
const AppVersion = styled('p')`
  margin-top: 0px;
  margin-bottom: 30px;
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.white};
`
const LanguageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px;
`

const CurrentLanguage = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 15px;
  color: ${(props) => props.theme.colors.white};
`
