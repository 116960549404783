type BannerType = 'NEWS' | 'INFORMATION' | 'WARNING' | 'ERROR'

interface BannerIconInfos {
  icon: IconName
  color: string
}

const getBannerIconInfos = (status: BannerType, theme: Theme): BannerIconInfos | undefined => {
  if (status === 'ERROR') {
    return {
      icon: 'cross-circle',
      color: theme.colors.toastErrorText,
    }
  }
  if (status === 'INFORMATION') {
    return {
      icon: 'info-circle2',
      color: theme.colors.blue,
    }
  }
  if (status === 'WARNING') {
    return {
      icon: 'warning-circle',
      color: theme.colors.orange,
    }
  }
  return undefined
}

export { getBannerIconInfos }
