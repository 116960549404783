import * as React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'theme/styled-components'

interface Props {
  name: IconName
  color?: string
  size?: number
  height?: number
  ariaLabel?: string
}

const icons: { [key in IconName]: string } = {
  'heart-crossed': require('./svg/heart-crossed.svg').default,
  heart: require('./svg/heart.svg').default,
  'heart-filled': require('./svg/heart-filled.svg').default,
  news: require('./svg/news.svg').default,
  home: require('./svg/home.svg').default,
  user: require('./svg/user.svg').default,
  restaurant: require('./svg/restaurant.svg').default,
  globe: require('./svg/globe.svg').default,
  text: require('./svg/text.svg').default,
  plus: require('./svg/plus.svg').default,
  less: require('./svg/less.svg').default,
  circle_arrow_left: require('./svg/circle-arrow-left.svg').default,
  circle_arrow_right: require('./svg/circle-arrow-right.svg').default,
  arrow_right: require('./svg/arrow-right.svg').default,
  arrow_updown: require('./svg/arrow-updown.svg').default,
  share: require('./svg/share.svg').default,
  agenda: require('./svg/agenda.svg').default,
  pin: require('./svg/pin.svg').default,
  clock: require('./svg/clock.svg').default,
  check: require('./svg/check.svg').default,
  'check-double': require('./svg/check-double.svg').default,
  'check-circle': require('./svg/check-circle.svg').default,
  transport: require('./svg/transport.svg').default,
  cross: require('./svg/cross.svg').default,
  engie: require('./svg/engie-logo.svg').default,
  arrow_out: require('./svg/arrow-out.svg').default,
  chevron_left: require('./svg/chevron-left.svg').default,
  chevron_right: require('./svg/chevron-right.svg').default,
  chevron_top: require('./svg/chevron_top.svg').default,
  chevron_bottom: require('./svg/chevron_bottom.svg').default,
  myPortalCampus: require('./svg/logo-MyPortalCampus.svg').default,
  myPortalCampusLetters: require('./svg/logo-MyPortalCampus-letters.svg').default,
  options: require('./svg/options.svg').default,
  settings: require('./svg/settings.svg').default,
  search: require('./svg/search.svg').default,
  itinerary: require('./svg/itinerary.svg').default,
  walking: require('./svg/walking.svg').default,
  intervention: require('./svg/intervention.svg').default,
  roombooking: require('./svg/roombooking.svg').default,
  thermometer: require('./svg/thermometer.svg').default,
  'burger-menu': require('./svg/burger-menu.svg').default,
  id_card: require('./svg/id-card.svg').default,
  'add-contact': require('./svg/add-contact.svg').default,
  'car-rental': require('./svg/car-rental.svg').default,
  desk: require('./svg/desk.svg').default,
  directory: require('./svg/directory.svg').default,
  drops: require('./svg/drops.svg').default,
  locker: require('./svg/locker.svg').default,
  parking: require('./svg/parking.svg').default,
  phone: require('./svg/phone.svg').default,
  wind: require('./svg/wind.svg').default,
  warning: require('./svg/warning.svg').default,
  logo: require('./svg/logo.svg').default,
  access_control: require('./svg/access_control.svg').default,
  authentification: require('./svg/authentification.svg').default,
  bell: require('./svg/bell.svg').default,
  blinds: require('./svg/blinds.svg').default,
  book: require('./svg/book.svg').default,
  bottom: require('./svg/bottom.svg').default,
  bubble: require('./svg/bubble.svg').default,
  building: require('./svg/building.svg').default,
  co2: require('./svg/co2.svg').default,
  compass: require('./svg/compass.svg').default,
  exit: require('./svg/exit.svg').default,
  external: require('./svg/external.svg').default,
  fan: require('./svg/fan.svg').default,
  flame: require('./svg/flame.svg').default,
  geoloc: require('./svg/geoloc.svg').default,
  group: require('./svg/group.svg').default,
  hour: require('./svg/hour.svg').default,
  'light-bulb': require('./svg/light-bulb.svg').default,
  logout: require('./svg/logout.svg').default,
  mail: require('./svg/mail.svg').default,
  map: require('./svg/map.svg').default,
  meetme: require('./svg/meetme.svg').default,
  pencil: require('./svg/pencil.svg').default,
  push: require('./svg/push.svg').default,
  qrcode: require('./svg/qrcode.svg').default,
  remote: require('./svg/remote.svg').default,
  reset: require('./svg/reset.svg').default,
  right: require('./svg/right.svg').default,
  room: require('./svg/room.svg').default,
  scan: require('./svg/scan.svg').default,
  send: require('./svg/send.svg').default,
  snowflake: require('./svg/snowflake.svg').default,
  stairs: require('./svg/stairs.svg').default,
  star: require('./svg/star.svg').default,
  target: require('./svg/target.svg').default,
  ticket: require('./svg/ticket.svg').default,
  trash: require('./svg/trash.svg').default,
  bin: require('./svg/bin.svg').default,
  undo: require('./svg/undo.svg').default,
  tv: require('./svg/tv.svg').default,
  '1h': require('./svg/1h.svg').default,
  bluetooth: require('./svg/bluetooth.svg').default,
  'a-tag-remove': require('./svg/a-tag-remove.svg').default,
  'a-tag': require('./svg/a-tag.svg').default,
  'accessibility-lift': require('./svg/accessibility-lift.svg').default,
  acorn: require('./svg/acorn.svg').default,
  'adaptive-bike': require('./svg/adaptive-bike.svg').default,
  'air-bomb': require('./svg/air-bomb.svg').default,
  'alarm-add': require('./svg/alarm-add.svg').default,
  'alarm-disable': require('./svg/alarm-disable.svg').default,
  ampersand: require('./svg/ampersand.svg').default,
  analytics: require('./svg/analytics.svg').default,
  antenna: require('./svg/antenna.svg').default,
  apps: require('./svg/apps.svg').default,
  arcade: require('./svg/arcade.svg').default,
  'arrow-tool': require('./svg/arrow-tool.svg').default,
  'at-sign-2': require('./svg/at-sign-2.svg').default,
  'at-sign': require('./svg/at-sign.svg').default,
  backup: require('./svg/backup.svg').default,
  'bag-delivery': require('./svg/bag-delivery.svg').default,
  barbell: require('./svg/barbell.svg').default,
  'basketball-player': require('./svg/basketball-player.svg').default,
  bicep: require('./svg/bicep.svg').default,
  'bike-bmx': require('./svg/bike-bmx.svg').default,
  blindness: require('./svg/blindness.svg').default,
  blog: require('./svg/blog.svg').default,
  'board-game': require('./svg/board-game.svg').default,
  bodybuilder: require('./svg/bodybuilder.svg').default,
  brioche: require('./svg/brioche.svg').default,
  'call-doctor': require('./svg/call-doctor.svg').default,
  car: require('./svg/car.svg').default,
  'car-parking': require('./svg/car-parking.svg').default,
  'car-wash': require('./svg/car-wash.svg').default,
  'check-list': require('./svg/check-list.svg').default,
  chef: require('./svg/chef.svg').default,
  'closed-captioning': require('./svg/closed-captioning.svg').default,
  'coffee-bean': require('./svg/coffee-bean.svg').default,
  concierge: require('./svg/concierge.svg').default,
  cutlery: require('./svg/cutlery.svg').default,
  cycling: require('./svg/cycling.svg').default,
  deaf: require('./svg/deaf.svg').default,
  'delete-forever': require('./svg/delete-forever.svg').default,
  dish: require('./svg/dish.svg').default,
  distance: require('./svg/distance.svg').default,
  doctor: require('./svg/doctor.svg').default,
  'dog-leash': require('./svg/dog-leash.svg').default,
  earbuds: require('./svg/earbuds.svg').default,
  'edit-brightness': require('./svg/edit-brightness.svg').default,
  'edit-curves': require('./svg/edit-curves.svg').default,
  energy: require('./svg/energy.svg').default,
  escalator: require('./svg/escalator.svg').default,
  'event-confirm': require('./svg/event-confirm.svg').default,
  'event-create': require('./svg/event-create.svg').default,
  'face-recognition': require('./svg/face-recognition.svg').default,
  'face-woman': require('./svg/face-woman.svg').default,
  'fav-property': require('./svg/fav-property.svg').default,
  female: require('./svg/female.svg').default,
  'final-score': require('./svg/final-score.svg').default,
  'finger-snap': require('./svg/finger-snap.svg').default,
  fireworks: require('./svg/fireworks.svg').default,
  'flight-connection': require('./svg/flight-connection.svg').default,
  flight: require('./svg/flight.svg').default,
  'furnished-property': require('./svg/furnished-property.svg').default,
  honeymoon: require('./svg/honeymoon.svg').default,
  interview: require('./svg/interview.svg').default,
  land: require('./svg/land.svg').default,
  landing: require('./svg/landing.svg').default,
  lemon: require('./svg/lemon.svg').default,
  'line-chart': require('./svg/line-chart.svg').default,
  'love-heart-pin': require('./svg/love-heart-pin.svg').default,
  'm-check': require('./svg/m-check.svg').default,
  'm-location': require('./svg/m-location.svg').default,
  'm-share': require('./svg/m-share.svg').default,
  'magnifier-zoom-out': require('./svg/magnifier-zoom-out.svg').default,
  magnifier: require('./svg/magnifier.svg').default,
  'medical-mask': require('./svg/medical-mask.svg').default,
  'military-medal': require('./svg/military-medal.svg').default,
  'no-results': require('./svg/no-results.svg').default,
  pacman: require('./svg/pacman.svg').default,
  park: require('./svg/park.svg').default,
  party: require('./svg/party.svg').default,
  payee: require('./svg/payee.svg').default,
  payor: require('./svg/payor.svg').default,
  peanut: require('./svg/peanut.svg').default,
  'phone-charging-3': require('./svg/phone-charging-3.svg').default,
  'pin-add-2': require('./svg/pin-add-2.svg').default,
  'pin-check': require('./svg/pin-check.svg').default,
  'pin-search': require('./svg/pin-search.svg').default,
  'pin-user': require('./svg/pin-user.svg').default,
  'plug-2': require('./svg/plug-2.svg').default,
  podcast: require('./svg/podcast.svg').default,
  position: require('./svg/position.svg').default,
  'power-lifting': require('./svg/power-lifting.svg').default,
  'pregnant-woman': require('./svg/pregnant-woman.svg').default,
  ranking: require('./svg/ranking.svg').default,
  'refresh-02': require('./svg/refresh-02.svg').default,
  'roast-chicken': require('./svg/roast-chicken.svg').default,
  'runny-nose': require('./svg/runny-nose.svg').default,
  satellite: require('./svg/satellite.svg').default,
  'save-to-list': require('./svg/save-to-list.svg').default,
  'screen-reader': require('./svg/screen-reader.svg').default,
  'search-property': require('./svg/search-property.svg').default,
  'security-officer': require('./svg/security-officer.svg').default,
  'send-to-phone': require('./svg/send-to-phone.svg').default,
  sensor: require('./svg/sensor.svg').default,
  'shape-arrow': require('./svg/shape-arrow.svg').default,
  'shape-star': require('./svg/shape-star.svg').default,
  shell: require('./svg/shell.svg').default,
  'sign-language': require('./svg/sign-language.svg').default,
  sneeze: require('./svg/sneeze.svg').default,
  'social-distancing': require('./svg/social-distancing.svg').default,
  'soft-drink': require('./svg/soft-drink.svg').default,
  soundwave: require('./svg/soundwave.svg').default,
  style: require('./svg/style.svg').default,
  subtitles: require('./svg/subtitles.svg').default,
  survey: require('./svg/survey.svg').default,
  terrace: require('./svg/terrace.svg').default,
  'theater-curtains': require('./svg/theater-curtains.svg').default,
  theater: require('./svg/theater.svg').default,
  toast: require('./svg/toast.svg').default,
  toilette: require('./svg/toilette.svg').default,
  'tool-hand': require('./svg/tool-hand.svg').default,
  'users-wm': require('./svg/users-wm.svg').default,
  'virtual-assistant-2': require('./svg/virtual-assistant-2.svg').default,
  walk: require('./svg/walk.svg').default,
  'waste-recycling': require('./svg/waste-recycling.svg').default,
  'watch-2': require('./svg/watch-2.svg').default,
  watch: require('./svg/watch.svg').default,
  'water-hand': require('./svg/water-hand.svg').default,
  'water-sink': require('./svg/water-sink.svg').default,
  'water-surface': require('./svg/water-surface.svg').default,
  'water-wave': require('./svg/water-wave.svg').default,
  wc: require('./svg/wc.svg').default,
  'wheelchair-2': require('./svg/wheelchair-2.svg').default,
  whisk: require('./svg/whisk.svg').default,
  'wifi-protected': require('./svg/wifi-protected.svg').default,
  wifi: require('./svg/wifi.svg').default,
  'wind-2': require('./svg/wind-2.svg').default,
  'wink-06': require('./svg/wink-06.svg').default,
  winner: require('./svg/winner.svg').default,
  'wireless-charging': require('./svg/wireless-charging.svg').default,
  'workout-plan': require('./svg/workout-plan.svg').default,
  world: require('./svg/world.svg').default,
  zoom: require('./svg/zoom.svg').default,
  banking: require('./svg/banking.svg').default,
  barbershop: require('./svg/barbershop.svg').default,
  crossroad: require('./svg/crossroad.svg').default,
  disinfectant: require('./svg/disinfectant.svg').default,
  hallway: require('./svg/hallway.svg').default,
  'hospital-bed': require('./svg/hospital-bed.svg').default,
  hot: require('./svg/hot.svg').default,
  lift: require('./svg/lift.svg').default,
  ordi: require('./svg/ordi.svg').default,
  printer: require('./svg/printer.svg').default,
  'toilet-paper': require('./svg/toilet-paper.svg').default,
  teams: require('./svg/teams.svg').default,
  info: require('./svg/info.svg').default,
  info2: require('./svg/info2.svg').default,
  'gauge-empty': require('./svg/gauge-empty.svg').default,
  'gauge-full': require('./svg/gauge-full.svg').default,
  'gauge-middle': require('./svg/gauge-middle.svg').default,
  'gauge-0': require('./svg/gauge_0.svg').default,
  'gauge-10': require('./svg/gauge_10.svg').default,
  'gauge-20': require('./svg/gauge_20.svg').default,
  'gauge-30': require('./svg/gauge_30.svg').default,
  'gauge-40': require('./svg/gauge_40.svg').default,
  'gauge-50': require('./svg/gauge_50.svg').default,
  'gauge-60': require('./svg/gauge_60.svg').default,
  'gauge-70': require('./svg/gauge_70.svg').default,
  'gauge-80': require('./svg/gauge_80.svg').default,
  'gauge-90': require('./svg/gauge_90.svg').default,
  'gauge-100': require('./svg/gauge_100.svg').default,
  'cross-circle': require('./svg/cross-circle.svg').default,
  'warning-circle': require('./svg/warning-circle.svg').default,
  eye: require('./svg/eye.svg').default,
  menu: require('./svg/menu.svg').default,
  'packed-lunch': require('./svg/packed-lunch.svg').default,
  'star-filled': require('./svg/star_filled.svg').default,
  'contactless-card': require('./svg/contactless-card.svg').default,
  'question-mark': require('./svg/question-mark.svg').default,
  'room-service': require('./svg/room-service.svg').default,
  'info-circle': require('./svg/info-circle.svg').default,
  'file-article': require('./svg/file-article.svg').default,
  magic: require('./svg/magic.svg').default,
  'info-circle2': require('./svg/info-circle2.svg').default,
  'warning-circle2': require('./svg/warning-circle2.svg').default,
}

export default ({ name, size = 20, color, height, ariaLabel }: Props) => {
  const iconSrc = icons[name || 'heart']

  return (
    <IconContainer size={size} color={color} height={height}>
      <SVG
        src={iconSrc}
        preProcessor={(code) =>
          code
            .replace(/ width=".*?"/g, ` width="${size}"`)
            .replace(/height=".*?"/g, `height="${height ? height : size}"`)
        }
        aria-hidden={!ariaLabel}
        role={!!ariaLabel ? 'img' : undefined}
        aria-label={ariaLabel}
        title={ariaLabel}
      />
    </IconContainer>
  )
}

const IconContainer = styled('div')<{ size: number; color?: string; height?: number }>`
  height: ${(props) => (props.height ? props.height : props.size)}px;
  width: ${(props) => props.size}px;
  ${(props) => !!props.color && `color: ${props.color};`}
`
