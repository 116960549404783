import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from '../api/restaurantsApi'
import barclapApi from 'planning/api'
import accountApi from 'account/accountApi'

import config from 'core/src/config'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import Logger from 'utils/Logger'
import analytics, { analyticsActionKeys, analyticsKeys } from 'utils/analytics'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as bannersStore from 'banners/store'

import RestaurantImage from '../RestaurantImage'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import BarclapScreen from 'planning/barclap/BarclapScreen'
import Modal from 'components/modal/Modal'
import PackedLunchScreen from 'planning/barclap/PackedLunchScreen'
import ServiceDetails from './ServiceDetails'
import Icons from 'components/icons/Icons'
import OccupationComponent from './OccupationComponent'
import Button from 'components/button/Button'
import Banner from 'components/banner/Banner'

import Loader from 'react-loader-spinner'

type Status = 'loading' | 'error' | 'success'
type ServicesBarclap = 'commuTable' | 'tableService' | 'packedLunch'

const NOW = new Date()

interface Props {
  initialService?: ServicesBarclap
}

// Id du site sur lequel on veut afficher un message introductif
const T1_ID = '2'

const RestaurantsScreen = ({ initialService }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const badges = useReducer(userStore.store, (s) => s.badges)

  const currentBadge = React.useMemo(() => (!!site ? badges.find((b) => b.siteId === site.id) : undefined), [
    site,
    badges,
  ])

  const soldeIsNotNaN = React.useMemo(() => currentBadge?.solde !== undefined && currentBadge?.solde !== null, [
    currentBadge,
  ])

  const [restaurants, setRestaurants] = React.useState<RestaurantV6[]>([])
  const [restaurantsStatus, setRestaurantsStatus] = React.useState<Status>('loading')
  const [servicesStatus, setServicesStatus] = React.useState<Status>('loading')
  const [siteModules, setSiteModules] = React.useState<BarclapModule[]>([])
  const [tableCommu, setTableCommu] = React.useState<BarclapModuleDatas>()
  const [tableService, setTableService] = React.useState<BarclapModuleDatas>()
  const [packedLunch, setPackedLunch] = React.useState<BarclapModuleDatas>()
  const [packedLunchBooking, setPackedLunchBooking] = React.useState<BarclapBooking>()
  const [tableCommuBooking, setTableCommuBooking] = React.useState<BarclapBooking>()
  const [tableServiceBooking, setTableServiceBooking] = React.useState<BarclapBooking>()
  const [initialModalOpened, setInitialModalOpened] = React.useState(false)

  const servicesVisible = React.useMemo(() => {
    const servicesList: ServicesBarclap[] = []
    if (!!site && !!site.barclapId && siteModules.length > 0) {
      if (siteModules.find((m) => m.module_uuid === config.barclap.packedLunchUUID)) {
        servicesList.push('packedLunch')
      }
      if (siteModules.find((m) => m.module_uuid === config.barclap.tableCommuUUID)) {
        servicesList.push('commuTable')
      }
      if (siteModules.find((m) => m.module_uuid === config.barclap.tableServiceUUID)) {
        servicesList.push('tableService')
      }
    }
    return servicesList
  }, [site, siteModules])

  const formatBarclapDate = (date: Date) => i18n.t('formats.utcDate', { date: date })

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'CATERING'),
    [banners]
  )

  React.useEffect(() => {
    if (user && site) {
      fetchRestaurants()
      fetchBadges()

      analytics.track({ screenName: analyticsKeys.restaurantList, userType: user.type, currentSite: site, mySites })
    }
  }, [])

  const fetchBadges = () => {
    if (user && site) {
      accountApi
        .getBadges(user.type)
        .then((result) => userStore.actions.setBadges(result.badges))
        .catch(Logger.error)
    }
  }

  const fetchRestaurants = () => {
    if (user && site) {
      api
        .getAllRestaurants(user.type, site.id)
        .then((restaurants) => {
          setRestaurantsStatus('success')
          fetchBarclapInfos()
          setRestaurants(restaurants.restaurants)
          if (restaurants.restaurants.length === 1) {
            navigation.push(`/catering/${restaurants.restaurants[0].id}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          setRestaurantsStatus('error')
        })
    }
  }

  React.useEffect(() => {
    if (!!initialService && !initialModalOpened && servicesStatus === 'success') {
      const tableServiceToOpen =
        !!tableService && servicesVisible.includes('tableService') && initialService === 'tableService'
      const tableCommuToOpen = !!tableCommu && servicesVisible.includes('commuTable') && initialService === 'commuTable'
      const packedLunchToOpen =
        !!packedLunch && servicesVisible.includes('packedLunch') && initialService === 'packedLunch'
      if (tableServiceToOpen || tableCommuToOpen) {
        setInitialModalOpened(true)
        Modal.open(() => (
          <BarclapScreen
            closeModal={Modal.close}
            moduleName={tableServiceToOpen ? 'tableService' : 'commuTable'}
            bookingTab="BOOKINGS"
            refreshModulesInfos={fetchBarclapInfos}
          />
        ))
      } else if (packedLunchToOpen && !!packedLunch) {
        setInitialModalOpened(true)
        Modal.open(() => (
          <PackedLunchScreen
            moduleInfos={packedLunch}
            closeModal={Modal.close}
            refreshModulesInfos={fetchBarclapInfos}
            mode={packedLunchBooking ? 'READ' : 'BOOK'}
          />
        ))
      }
    }
  }, [servicesVisible, tableService, tableCommu, packedLunch, initialModalOpened, servicesStatus])

  const fetchBarclapInfos = () => {
    if (user && site && site.barclapId) {
      Promise.all([
        barclapApi.barclap.getSiteModules(user.type, site.id),
        barclapApi.barclap.getBookings(
          user.type,
          site.id,
          site.barclapId!,
          formatBarclapDate(NOW),
          formatBarclapDate(NOW)
        ),
      ])
        .then((res) => {
          const findPackedLunchBooking = res[1].find(
            (booking) => booking.module_uuid === config.barclap.packedLunchUUID
          )
          setPackedLunchBooking(findPackedLunchBooking)
          const findCommuTableBooking = res[1].find((booking) => booking.module_uuid === config.barclap.tableCommuUUID)
          setTableCommuBooking(findCommuTableBooking)
          const findTableServiceBooking = res[1].find(
            (booking) => booking.module_uuid === config.barclap.tableServiceUUID
          )
          setTableServiceBooking(findTableServiceBooking)
          const findSiteModules = res[0].find((s) => s.site_uuid === site.barclapId)
          if (!!findSiteModules) {
            const filterModules = findSiteModules.teams[0].modules.filter(
              (m) =>
                m.module_uuid === config.barclap.packedLunchUUID ||
                m.module_uuid === config.barclap.tableCommuUUID ||
                m.module_uuid === config.barclap.tableServiceUUID
            )
            setSiteModules(filterModules)
            const promises = filterModules.map((moduleInfo) =>
              barclapApi.barclap.getModuleInfos(
                moduleInfo.module_uuid === config.barclap.packedLunchUUID ? 's4d9' : 's4d1',
                user.type,
                site.id,
                moduleInfo.module_uuid,
                site.barclapId!,
                formatBarclapDate(NOW)
              )
            )
            Promise.all(promises)
              .then((res) => {
                setServicesStatus('success')
                res.map((data) => {
                  if (data.module.module_uuid === config.barclap.tableServiceUUID) {
                    setTableService(data)
                  } else if (data.module.module_uuid === config.barclap.tableCommuUUID) {
                    setTableCommu(data)
                  } else if (data.module.module_uuid === config.barclap.packedLunchUUID) {
                    setPackedLunch(data)
                  }
                })
              })
              .catch((err) => {
                Logger.error(err)
                setServicesStatus('error')
              })
          }
        })
        .catch((err) => {
          Logger.error(err)
          setServicesStatus('error')
        })
    } else {
      setServicesStatus('success')
    }
  }

  const RestaurantComponent = (restaurant: RestaurantV6) => {
    const openingDate = new Date(
      i18n.t('screens.catering.openingTimes.dateFormat', { date: NOW }) + 'T' + restaurant.openingTime
    )
    const closingDate = new Date(
      i18n.t('screens.catering.openingTimes.dateFormat', { date: NOW }) + 'T' + restaurant.closingTime
    )

    const goToDetails = () => navigation.push(`/catering/${restaurant.id}`)

    return (
      <RestaurantContainer
        key={restaurant.id}
        onClick={goToDetails}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            goToDetails()
          }
        }}>
        <ImageContainer>
          <RestaurantImage width={372} height={230} imageURL={restaurant.photo} />
        </ImageContainer>
        <InfosContainer>
          <Name>{restaurant.name}</Name>
          <RoomStatusContainer>
            <StatusIndicator free={restaurant.status === 'OPEN'} />
            <StatusText free={restaurant.status === 'OPEN'}>
              {i18n.t(`screens.catering.status.${restaurant.status.toLowerCase()}`)}
            </StatusText>
          </RoomStatusContainer>
          {restaurant.restaurantType && (
            <ScheduleText>
              {i18n.t(`screens.catering.openingTimes.${restaurant.restaurantType.toLowerCase()}`, {
                time1: openingDate,
                time2: closingDate,
              })}
            </ScheduleText>
          )}
          {(!!restaurant.currentCapacity || restaurant.currentCapacity === 0) &&
            restaurant.currentCapacity !== -1 &&
            !!restaurant.maxCapacity && (
              <OccupationContainer>
                <IconUserContainer>
                  <Icons name="users-wm" size={16} color={Theme.colors.blue} />
                </IconUserContainer>
                <OccupationText>
                  {i18n.t('screens.catering.occupation', {
                    currentCapacity: restaurant.currentCapacity,
                    maxCapacity: restaurant.maxCapacity,
                  })}
                </OccupationText>
                <OccupationComponent
                  currentCapacity={restaurant.currentCapacity}
                  maxCapacity={restaurant.maxCapacity}
                />
              </OccupationContainer>
            )}
        </InfosContainer>
      </RestaurantContainer>
    )
  }

  const renderServices = () => {
    if ((servicesVisible.length === 0 || servicesStatus === 'error') && !soldeIsNotNaN) {
      return null
    }
    return (
      <ServicesContainer>
        <ServiceTitleContainer>
          <TitleText>{i18n.t('screens.catering.services.title')}</TitleText>
        </ServiceTitleContainer>

        <ServicesListContainer>
          {soldeIsNotNaN && (
            <ServiceDetails
              title={i18n.t(`screens.catering.services.solde`)}
              iconName="contactless-card"
              serviceName="solde"
              onClick={() => navigation.push('/mymrs')}
              width={372}
            />
          )}
          {!!tableService && servicesVisible.includes('tableService') && (
            <ServiceDetails
              title={i18n.t(`screens.catering.services.tableService`)}
              barclapModuleData={tableService}
              iconName="packed-lunch"
              serviceName="tableService"
              onClick={() =>
                Modal.open(() => (
                  <BarclapScreen
                    closeModal={Modal.close}
                    moduleName="tableService"
                    bookingTab={!!tableServiceBooking ? 'BOOKINGS' : 'FORM'}
                    refreshModulesInfos={fetchBarclapInfos}
                  />
                ))
              }
              packedLunchBooking={packedLunchBooking}
              tableCommuBooking={tableCommuBooking}
              tableServiceBooking={tableServiceBooking}
              width={372}
            />
          )}
          {!!tableCommu && servicesVisible.includes('commuTable') && (
            <ServiceDetails
              title={i18n.t(`screens.catering.services.commuTable`)}
              barclapModuleData={tableCommu}
              iconName="users-wm"
              serviceName="commuTable"
              onClick={() =>
                Modal.open(() => (
                  <BarclapScreen
                    closeModal={Modal.close}
                    moduleName="commuTable"
                    bookingTab={!!tableCommuBooking ? 'BOOKINGS' : 'FORM'}
                    refreshModulesInfos={fetchBarclapInfos}
                  />
                ))
              }
              packedLunchBooking={packedLunchBooking}
              tableCommuBooking={tableCommuBooking}
              tableServiceBooking={tableServiceBooking}
              width={372}
            />
          )}
          {!!packedLunch && servicesVisible.includes('packedLunch') && (
            <ServiceDetails
              title={i18n.t(`screens.catering.services.packedLunch`)}
              barclapModuleData={packedLunch}
              iconName="bag-delivery"
              serviceName="packedLunch"
              onClick={() =>
                Modal.open(() => (
                  <PackedLunchScreen
                    moduleInfos={packedLunch}
                    closeModal={Modal.close}
                    refreshModulesInfos={fetchBarclapInfos}
                    mode={packedLunchBooking ? 'READ' : 'BOOK'}
                  />
                ))
              }
              packedLunchBooking={packedLunchBooking}
              tableCommuBooking={tableCommuBooking}
              tableServiceBooking={tableServiceBooking}
              width={372}
            />
          )}
          {site?.commandRoom && (
            <ServiceDetails
              title={i18n.t(`screens.catering.services.roomService`)}
              iconName="room-service"
              serviceName="roomService"
              onClick={() => {
                analytics.track({
                  screenName: analyticsKeys.home,
                  userType: user?.type,
                  currentSite: site,
                  mySites,
                  action: analyticsActionKeys.roomService,
                })
                navigation.push(`/information/${site.commandRoom}`)
              }}
              width={372}
            />
          )}
        </ServicesListContainer>
      </ServicesContainer>
    )
  }

  if (restaurantsStatus === 'loading' || servicesStatus === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (restaurantsStatus === 'error') {
    return <ErrorMessage>{i18n.t('screens.catering.restaurantsError')}</ErrorMessage>
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.catering.title')} />
      <Breadcrumb screen="catering" path="catering" navigation={navigation} />

      {!!showedBanner && <Banner banner={showedBanner} marginTop={20} marginLeft={60} marginRight={60} />}

      {restaurants.length > 0 ? (
        <>
          <RestaurantsContainer>
            <RestaurantTitleContainer>
              <TitleContainer>
                <TitleText>{i18n.t('screens.catering.title', { count: restaurants.length })}</TitleText>
                {site?.id === T1_ID && (
                  <IconInfosContainer
                    onClick={() =>
                      Modal.open(() => (
                        <IntroTextContainer>
                          <IntroText>{i18n.t('screens.catering.introText.description')}</IntroText>
                          <BulletPointContainer>
                            <BulletPoint />
                            <IntroText>{i18n.t('screens.catering.introText.option1')}</IntroText>
                          </BulletPointContainer>
                          <BulletPointContainer>
                            <BulletPoint />
                            <IntroText>{i18n.t('screens.catering.introText.option2')}</IntroText>
                          </BulletPointContainer>
                          <BulletPointContainer>
                            <BulletPoint />
                            <IntroText>{i18n.t('screens.catering.introText.option3')}</IntroText>
                          </BulletPointContainer>
                          <ButtonContainer>
                            <Button
                              label={i18n.t('common.back')}
                              onClick={Modal.close}
                              color={Theme.colors.blue}
                              textColor={Theme.colors.white}
                              shadow
                              font={Theme.fonts.h3Bold}
                            />
                          </ButtonContainer>
                        </IntroTextContainer>
                      ))
                    }>
                    <Icons name="info" size={30} color={Theme.colors.blue} />
                  </IconInfosContainer>
                )}
              </TitleContainer>
            </RestaurantTitleContainer>
            <RestaurantsListContainer>
              {restaurants.map((restaurant) => RestaurantComponent(restaurant))}
            </RestaurantsListContainer>
          </RestaurantsContainer>
          {renderServices()}
        </>
      ) : (
        <ErrorMessage>{i18n.t('screens.catering.noRestaurants')}</ErrorMessage>
      )}
    </>
  )
}

export default RestaurantsScreen

// CONTAINERS

const RestaurantsContainer = styled('div')`
  margin: 20px 60px 40px 60px;
  display: flex;
  flex-direction: column;
`

const RestaurantTitleContainer = styled('div')`
  margin: 20px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-bottom: 10px;
  }
`

const RestaurantsListContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    align-items: center;
    padding-top: 0px;
    margin-left: 0px;
  }
`
const RestaurantContainer = styled('div')`
  width: 372px;
  cursor: pointer;
  margin: 0px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 20px 0px 0px 0px;
  }
`
const ServicesContainer = styled('div')`
  margin-bottom: 60px;
`
const ServiceTitleContainer = styled('div')`
  margin-left: 60px;
  margin-bottom: 10px;
`

const ServicesListContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-bottom: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    align-items: center;
    padding-top: 0px;
    margin-left: 0px;
  }
`
const IconInfosContainer = styled('div')`
  margin-left: 20px;
  cursor: pointer;
`
const ImageContainer = styled('div')`
  display: flex;
  height: 230px;
  width: 372px;
  background-color: ${(props) => props.theme.colors.placeholderGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const RoomStatusContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const OccupationContainer = styled('div')`
  display: flex;
  gap: 10px;
  margin-top: 8px;
  align-items: center;
`

const IconUserContainer = styled('div')`
  justify-content: center;
`

// TEXTES

const Name = styled('p')`
  ${(props) => props.theme.fonts.thinTitle}
  margin: 0px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
`
const StatusText = styled('p')<{ free: boolean }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => (props.free ? props.theme.colors.turquoise : props.theme.colors.red)};
  margin: 0px;
`
const ScheduleText = styled.p`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  margin-left: 100px;
  margin-top: 50px;
`

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row:
`

const InfosContainer = styled('div')`
  margin-left: 16px;
`

const TitleText = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  font-size: 24px;
  line-height: 26px;
  margin: 0px;
`

const OccupationText = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
`

// AUTRES

const StatusIndicator = styled('div')<{ free: boolean }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => (props.free ? props.theme.colors.turquoise : props.theme.colors.red)};
  border-radius: 2px;
  margin: 0px 5px;
`

const IntroText = styled('h3')`
  ${(props) => props.theme.fonts.body};
`

const IntroTextContainer = styled('div')`
  margin-top: 16px;
  white-space: pre-wrap;
  padding: 16px;
  display: flex;
  max-width: 400px;
  flex-direction: column;
`

const ButtonContainer = styled('div')`
  margin: 10px;
  display: flex;
  flex: 1;
`

const BulletPointContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 0px;
  align-items: center;
`

const BulletPoint = styled('div')`
  background-color: ${(props) => props.theme.colors.black};
  height: 8px;
  width: 8px;
  border-radius: 16px;
  margin-right: 8px;
`
