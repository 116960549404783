import React from 'react'
import styled from 'theme/styled-components'
import { useHistory, useLocation } from 'react-router-dom'

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import ScrollToTop from '../ScrollToTop'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Screens from './Screens'
import Header from './Header'
import Drawer from './Drawer'
import Footer from 'components/layout/Footer'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as cguStore from 'cgu/cguStore'
import * as featureStore from 'sites/store/featureStore'
import * as iframeStore from 'iframe/iframeStore'
import * as languageStore from 'store/lang/languageStore'

import useI18n from 'i18n/useI18n'
import * as I18nStore from 'i18n/i18n'

import { checkOrigin, queryToRoute, deeplinkLoading } from './utils'
import { getScreenNameFromFeatureType } from 'home/utils'
import Logger from 'utils/Logger'
import { blobToB64, executeGraphRequest } from 'authent365/utils/utils'
import { Language } from 'i18n/types'

import cguApi from 'cgu/api'
import sitesApi from 'sites/api/sitesApi'
import accountApi from 'account/accountApi'
import parkingApi from 'parking/parkingApi'

import useNavigation from 'utils/navigation/useNavigation'

const screenTab: ScreenDefinition[] = Object.keys(Screens).map((key) => Screens[key])
const parent = '/:path'
const DEEPLINK_PATH = 'fonctionnalite'

const MainLayout = () => {
  const i18n = useI18n()

  const badges = useReducer(userStore.store, (s) => s.badges)

  const user = useReducer(userStore.store, (u) => u.user)

  const site = useReducer(siteStore.store, (s) => s.site)

  const features = useReducer(featureStore.store, (f) => f.features)

  const currentBadge = !!site ? badges.find((b) => b.siteId === site.id) : undefined

  const badgeSoldeIsNotNaN = currentBadge?.solde !== undefined && currentBadge?.solde !== null

  const currentSiteHasParking = !!features.find((feature) => feature.type === 'PARKING')

  React.useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.lang)
    cguApi.getTermsAndConditions(i18n.lang).then(cguStore.actions.setTermsAndConditions).catch(Logger.error)
  }, [i18n.lang])

  React.useEffect(() => {
    if (user) {
      sitesApi
        .getAllSitesLight(user.type)
        .then((allSitesReponse) => siteStore.actions.setAllSites(allSitesReponse.sites))
        .catch(Logger.error)

      if (site && !deeplinkLoading) {
        sitesApi.getSite(site.id, user.type).then(siteStore.actions.setSite).catch(Logger.error)
      }

      accountApi
        .getBadges(user.type)
        .then((result) => userStore.actions.setBadges(result.badges))
        .catch(Logger.error)
    }
  }, [user])

  React.useEffect(() => {
    if (currentSiteHasParking) {
      parkingApi.getParkingUrl().then(siteStore.actions.setParkingUrl).catch(Logger.error)
    }
  }, [currentSiteHasParking])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Route path={parent}>
        {({ match, location }) => {
          if (!!location.search && !user) {
            const query = queryToRoute(location.search, screenTab, badgeSoldeIsNotNaN)
            userStore.actions.setTemporaryDeeplink(query as string)
          }
          const path = match && match.params && match.params.path
          if (path === DEEPLINK_PATH) {
            const origin = checkOrigin()
            if (origin === 'android') {
              window.location.replace(
                'https://play.google.com/store/apps/details?id=com.engie.myportal.campus&hl=fr&gl=US'
              )
            } else if (origin === 'iPhone') {
              window.location.replace('https://apps.apple.com/en/app/living-by-my-portal/id1570642769')
            }
            return <Redirect to={`/${i18n.lang}${queryToRoute(location.search, screenTab, badgeSoldeIsNotNaN)}`} />
          }

          const lng = i18n.supportedLang.find((l: string) => match && match.params && match.params.path === l)
          return <MainRoute lng={lng} />
        }}
      </Route>
    </BrowserRouter>
  )
}

const MainRoute = ({ lng }: { lng?: Language }) => {
  const site = useReducer(siteStore.store, (s) => !!s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const allCGU = useReducer(cguStore.store, (s) => s.acceptedVersion)
  const acceptedCGU = React.useMemo(
    () => (!!user && (user.id || user.email) ? allCGU[(user.id || user.email) as string] : 'not_set'),
    [allCGU, user]
  )
  const termsAndConditions = useReducer(cguStore.store, (s) => s.termsAndConditions)

  const features: FeatureV4[] = useReducer(featureStore.store, (f) => f.features)
  const iframeLink: string = useReducer(iframeStore.store, (s) => s.iframeURL)
  const preferredLanguage = useReducer(languageStore.store, (s) => s.preferredLanguage)
  const temporaryDeeplink = useReducer(userStore.temporaryDeeplinkStore, (u) => u.temporaryDeeplink)

  const [userPicture, setUserPicture] = React.useState<string>()

  const history = useHistory()
  const location = useLocation()
  const i18n = useI18n()
  const navigation = useNavigation()

  // Si venue sur site et parking sharvy activé, ne pas afficher parking sharvy
  const hideSharvy = React.useMemo(
    () => !!features.find((f) => f.type === 'SHARVY_PARKING') && !!features.find((f) => f.type === 'SITE'),
    [features]
  )

  const currentScreen = screenTab.filter(
    (s) =>
      s.path === location.pathname.replace(`${i18n.lang}/`, '') ||
      s.path === location.pathname.replace(`${i18n.lang}`, '')
  )

  let prevPathName: string | null = null
  const focusRef = React.useRef<any>(null)
  const drawerRef = React.useRef<any>()
  const headerRef = React.useRef<any>()
  const contentRef = React.useRef<any>()
  const footerRef = React.useRef<any>()

  React.useEffect(() => {
    // Reset le focus lors de la navigation
    history.listen(({ pathname }) => {
      // don't refocus if only the query params/hash have changed
      if (pathname !== prevPathName) {
        focusRef.current?.focus()

        // prevent jank if focusing causes page to scroll
        window.scrollTo(0, 0)
      }
    })
  }, [])

  React.useEffect(() => {
    if (!!temporaryDeeplink && !!user && !!site) {
      setTimeout(() => navigation.push(temporaryDeeplink), 200)
      userStore.actions.setTemporaryDeeplink(undefined)
    }
  }, [temporaryDeeplink, user, site])

  React.useEffect(() => {
    if (!!user && user.type !== 'EXTERNAL') {
      executeGraphRequest<Blob>(user.type, `/users/${user.id}/photos/48x48/$value`, 'GET', undefined, 'blob')
        .then(blobToB64)
        .then(setUserPicture)
        .catch(Logger.error)
    }
  }, [user])

  const featuresAccessibleToAll: ScreenName[] = [
    'account',
    'cgu',
    'cguDetails',
    'privacyDetails',
    'home',
    'sites',
    'traffic_info',
    'sitemap',
    'pdf',
    'surveys',
    'myMRS',
  ]

  const [drawerOpened, setDrawerOpened] = React.useState(false)

  React.useEffect(() => {
    if (lng) {
      I18nStore.actions.setLang(lng)
      languageStore.actions.setLanguage(lng)
    }
  }, [lng])

  const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
    if (e.key === 'Enter') {
      onClick()
      window.scrollTo(0, 0)
    }
  }

  if (!lng) {
    if (preferredLanguage) {
      if (location.pathname) {
        return <Redirect to={`/${preferredLanguage}${location.pathname}`} />
      }
      return <Redirect to={`/${preferredLanguage}`} />
    } else {
      const defaultLanguage = !!I18nStore.locales[navigator.language.substring(0, 2)]
        ? navigator.language.substring(0, 2)
        : 'en'
      if (location.pathname) {
        return <Redirect to={`/${defaultLanguage}${location.pathname}`} />
      }
      return <Redirect to={`/${defaultLanguage}`} />
    }
  }

  if (location.pathname.includes('/totem') && !user) {
    const query = new URLSearchParams(location.search)
    const isTotemTransport = query.get('page') === 'transport'

    const TotemScreen = isTotemTransport ? Screens.totemTransport : Screens.totem
    return (
      <MainContainer>
        <Route path={parent + TotemScreen.path} exact render={() => <TotemScreen.Screen screen={TotemScreen.name} />} />
      </MainContainer>
    )
  }

  if (!user) {
    const screens = [Screens.login, Screens.cgu, Screens.trafficInfo, Screens.cguDetails, Screens.privacyDetails]

    return (
      <MainContainer>
        <OnlySwitchContainer>
          <Switch>
            {screens.map((screen) => (
              <Route
                path={parent + screen.path}
                exact
                key={screen.name}
                render={() => (
                  <ScreenContainer role="main">
                    <screen.Screen screen={screen.name} />
                  </ScreenContainer>
                )}
              />
            ))}
            <Redirect to={`/${lng}/login`} />
          </Switch>
        </OnlySwitchContainer>
      </MainContainer>
    )
  }

  if (
    acceptedCGU !== 'not_set' &&
    termsAndConditions &&
    termsAndConditions.generalTermsOfUse &&
    acceptedCGU !== termsAndConditions.generalTermsOfUse.version
  ) {
    const screens = [Screens.cgu, Screens.cguDetails, Screens.privacyDetails]

    return (
      <MainContainer>
        <HeaderContainer role="banner">
          <Header drawerOpened={drawerOpened} setDrawerOpened={setDrawerOpened} userPicture={userPicture} />
        </HeaderContainer>
        <FirstLogInSwitchContainer>
          <Switch>
            {screens.map((screen) => (
              <Route
                path={parent + screen.path}
                exact
                key={screen.name}
                render={() => (
                  <ScreenContainer role="main">
                    <screen.Screen screen={screen.name} />
                  </ScreenContainer>
                )}
              />
            ))}
            <Redirect to={`/${lng}/cgu`} />
          </Switch>
        </FirstLogInSwitchContainer>
      </MainContainer>
    )
  }

  if (!site) {
    const screens = [Screens.sites, Screens.account, Screens.cgu, Screens.cguDetails, Screens.privacyDetails]
    return (
      <MainContainer>
        <HeaderContainer role="banner">
          <Header drawerOpened={drawerOpened} setDrawerOpened={setDrawerOpened} userPicture={userPicture} />
        </HeaderContainer>
        <OnlySitesSwitchContainer>
          <Switch>
            {screens.map((screen) => (
              <Route
                path={parent + screen.path}
                exact
                key={screen.name}
                render={() => (
                  <>
                    <ScreenContainer role="main">
                      <screen.Screen screen={screen.name} />
                    </ScreenContainer>
                    {screen.footer && (
                      <FooterContainer ref={footerRef} tabIndex={-1} role="contentinfo">
                        <Footer />
                      </FooterContainer>
                    )}
                  </>
                )}
              />
            ))}
            <Redirect to={`/${lng}/sites`} />
          </Switch>
        </OnlySitesSwitchContainer>
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      <div ref={focusRef} tabIndex={-1}>
        <LinkTab tabIndex={0} onKeyDown={(e) => onEnterKey(e, () => headerRef.current.focus())}>
          <LinkText>{i18n.t('accessibility.goToHeader')}</LinkText>
        </LinkTab>
        <LinkTab tabIndex={0} onKeyDown={(e) => onEnterKey(e, () => drawerRef.current.focus())}>
          <LinkText>{i18n.t('accessibility.goToDrawer')}</LinkText>
        </LinkTab>
        <LinkTab tabIndex={0} onKeyDown={(e) => onEnterKey(e, () => contentRef.current.focus())}>
          <LinkText>{i18n.t('accessibility.goToContent')}</LinkText>
        </LinkTab>
        {currentScreen[0] && currentScreen[0].footer && (
          <LinkTab tabIndex={0} onKeyDown={(e) => onEnterKey(e, () => footerRef.current.focus())}>
            <LinkText>{i18n.t('accessibility.goToFooter')}</LinkText>
          </LinkTab>
        )}
      </div>
      <HeaderContainer ref={headerRef} tabIndex={-1} role="banner">
        <Header drawerOpened={drawerOpened} setDrawerOpened={setDrawerOpened} userPicture={userPicture} />
      </HeaderContainer>
      <DrawerContainer
        opened={drawerOpened}
        ref={drawerRef}
        tabIndex={-1}
        role="navigation"
        aria-label={i18n.t('label.mainNav')}>
        <Drawer drawerOpened={drawerOpened} setDrawerOpened={setDrawerOpened} />
      </DrawerContainer>
      <SwitchContainer ref={contentRef} tabIndex={-1}>
        <Switch>
          {screenTab
            .filter((s) => s.name !== 'login')
            .filter((s) => {
              if (hideSharvy && s.name === 'sharvyParking') {
                return false
              }
              const featuresName = features.map((f) => getScreenNameFromFeatureType(f.type))

              switch (s.name) {
                case 'newsDetails':
                  return featuresName.indexOf('NEWS') > -1

                case 'informationDetails':
                  return featuresName.indexOf('PRACTICAL_INFORMATION') > -1

                case 'roomDetails':
                  return featuresName.indexOf('ROOM') > -1

                case 'restaurantDetails':
                  return featuresName.indexOf('CATERING') > -1

                case 'iframe':
                  return !!iframeLink

                case 'surveysDetails':
                case 'otherServices':
                case 'poi':
                  return true
                default:
                  return featuresName.indexOf(s.name) > -1 || featuresAccessibleToAll.indexOf(s.name) > -1
              }
            })
            .map((scr) => (
              <Route
                key={scr.name}
                exact
                path={parent + scr.path}
                render={({ match, history, location }) => {
                  return (
                    <>
                      <ScreenContainer role="main">
                        <scr.Screen
                          {...match.params}
                          search={location.search}
                          {...(history.location.state as any)}
                          screen={scr.name}
                        />
                      </ScreenContainer>
                      {scr.footer && (
                        <FooterContainer ref={footerRef} tabIndex={-1} role="contentinfo">
                          <Footer />
                        </FooterContainer>
                      )}
                    </>
                  )
                }}
              />
            ))}
          <Redirect to="/" />
        </Switch>
      </SwitchContainer>
    </MainContainer>
  )
}

export default MainLayout

const MainContainer = styled('div')`
  cursor: default;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.colors.white};
  width: 100vw;
  height: 100vh;
  margin: -8px;
  overflow: hidden;
`
const LinkTab = styled('div')`
  position: fixed;
  display: flex;
  left: -100%;
  z-index: 100;
  outline: 2px solid ${(props) => props.theme.colors.white};
  top: 15px;
  align-items: center;
  height: 30px;
  padding: 0px 5px 2px 5px;
  :focus {
    left: 25%;
  }
`
const LinkText = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
`
const HeaderContainer = styled('header')`
  z-index: 20;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  :focus {
    outline: 0;
  }
`
const DrawerContainer = styled('nav')<{ opened: boolean }>`
  z-index: 25;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  :focus {
    outline: 0;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    ${(props) => !props.opened && 'left: -330px'};
    transition: 0.25s ease;
  }
`
const SwitchContainer = styled('div')`
  position: absolute;
  top: 60px;
  left: 114px;
  height: calc(100vh - 60px);
  min-width: calc(100vw - 114px - calc(100vw - 100%));
  :focus {
    outline: 0;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    height: calc(100vh - 130px);
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100vw;
    left: 0px;
    margin-bottom: 0px;
  }
`
const FooterContainer = styled('footer')`
  :focus {
    outline: 0;
  }
`
const OnlySitesSwitchContainer = styled('div')`
  top: 100px;
  position: absolute;
  height: calc(100vh - 156px);
  min-width: calc(100vw - calc(100vw - 100%));
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    height: calc(100vh - 226px);
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100vw;
    left: 0px;
    margin-bottom: 0px;
  }
`
const OnlySwitchContainer = styled('div')`
  display: flex;
`
const FirstLogInSwitchContainer = styled('div')`
  margin-top: 60px;
  display: flex;
`
const ScreenContainer = styled('main')`
  min-height: calc(100vh - 146px);
`
