import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import FocusLock from 'react-focus-lock'

const ANIM_DELAY = 200

interface Props {
  Content: () => JSX.Element
  fullScreen?: boolean
  zIndex?: number
  borderRadius?: number
}

const Modal = ({ Content, fullScreen, zIndex, borderRadius }: Props) => {
  const [opened, setOpened] = React.useState(false)

  const fadeout = () => {
    setOpened(false)
    setTimeout(ModalFunction.close, ANIM_DELAY)
  }

  const onKeyDown: React.KeyboardEventHandler = (evt) => {
    if (evt.key === 'Escape') {
      ModalFunction.close()
    }
  }

  React.useEffect(() => {
    setOpened(true)

    window.onpopstate = ModalFunction.close
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <MainContainer opened={opened} onKeyDownCapture={onKeyDown} zIndex={zIndex}>
      <FocusLock autoFocus={true}>
        <ModalContainer fullScreen={fullScreen} borderRadius={borderRadius}>
          <Content />
        </ModalContainer>
      </FocusLock>
      <BlackScreen onClick={fadeout} />
    </MainContainer>
  )
}

const MainContainer = styled('div')<{ opened: boolean; zIndex?: number }>`
  transition: opacity 0.2s;
  opacity: ${(props) => (props.opened ? 1 : 0)};

  z-index: ${(props) => props.zIndex || '51'};
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

const BlackScreen = styled('div')`
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.black};

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: -1;

  :hover {
    cursor: pointer;
  }
`

const ModalContainer = styled('div')<{ fullScreen?: boolean; borderRadius?: number }>`
  background-color: ${(props) => props.theme.colors.white};

  max-height: 90vh;
  max-width: 90vw;
  display: flex;
  flex-direction: column;

  overflow: auto;

  ${(props) => !!props.borderRadius && `border-radius: ${props.borderRadius}px;`}

  ${(props) =>
    props.fullScreen &&
    `
    height: calc(100vh - 48px);
    width: calc(100vw - 48px);
    max-height: 100vh;
    display: flex;
    `};
`

let modalRoot: HTMLElement | null
let active = false

let elementToFocusOnModalClose: any | null

const ModalFunction = {
  open: (content: () => JSX.Element, fullScreen?: boolean, zIndex?: number, borderRadius?: number) => {
    elementToFocusOnModalClose = document.activeElement
    modalRoot = document.getElementById('modal_root')
    if (modalRoot && active) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <Modal Content={content} fullScreen={fullScreen} zIndex={zIndex} borderRadius={borderRadius} />
        </Provider>,
        modalRoot
      )
    }
    active = true
  },
  close: () => {
    if (modalRoot && active) {
      ReactDOM.unmountComponentAtNode(modalRoot)
      active = false
      if (elementToFocusOnModalClose) {
        elementToFocusOnModalClose.focus()
        elementToFocusOnModalClose = null
      }
    }
  },
}

export default ModalFunction
