import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import config from 'core/src/config'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import Logger from 'utils/Logger'
import analytics, { analyticsKeys } from 'utils/analytics'
import useNavigation from 'utils/navigation/useNavigation'

import api from './api'
import accountApi from 'account/accountApi'
import graphApi from 'directory/api'
import zapfloorApi from 'planning/api'
import engieApi from 'directory/engie/apiEngieDirectory'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as bannersStore from 'banners/store'

import Button from 'components/button/Button'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Modal from 'components/modal/Modal'
import Directory from 'directory/DirectoryScreen'
import VisitInfo from './VisitInfo'
import ValidationContent from 'components/modal/ValidationContent'
import Alert from 'components/alert/Alert'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import TimePickerInput from 'components/picker/TimePickerInput'
import { FormTextInput } from 'components/form/InterventionFormItems'
import Banner from 'components/banner/Banner'

import Loader from 'react-loader-spinner'
import FocusLock from 'react-focus-lock'
import {
  isSameDay,
  setMinutes,
  setHours,
  addBusinessDays,
  getDay,
  differenceInBusinessDays,
  compareAsc,
} from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { addMonths } from 'date-fns'
import { I18n } from 'i18n/types'
import { todayVisitCanBeModified } from './utils'
import { getDayTimezone } from 'planning/utils'

type Status = 'ok' | 'error' | 'loading'

type Values = Pick<
  VisitRegisterFormV6,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'hostEngieUserId'
  | 'vehiclePlateNumber'
  | 'company'
  | 'visitedPhoneNumber'
  | 'comment'
>

const MAX_REGISTRATION_MONTH = 3

const INTERNAL_EMAILS = ['engie.com', 'external.engie.com', 'equans.com', 'external.equans.com', 'eras.com']

interface Props {
  email?: string
}

const formValidationSchema = (i18n: I18n, isEmailRequired: boolean, isPhoneRequired: boolean) =>
  Yup.object().shape({
    firstName: Yup.string().required(i18n.t('errors.form.mandatory')),
    lastName: Yup.string().required(i18n.t('errors.form.mandatory')),
    email: isEmailRequired
      ? Yup.string().email(i18n.t('errors.form.email')).required(i18n.t('errors.form.mandatory'))
      : Yup.string().email(i18n.t('errors.form.email')),
    visitedPhoneNumber: isPhoneRequired ? Yup.string().trim().required(i18n.t('errors.form.mandatory')) : Yup.string(),
  })

const RegistrationScreen = ({ email }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const now = new Date()
  const maxRegistrationDate = addMonths(now, MAX_REGISTRATION_MONTH)

  const formRef = React.useRef<HTMLDivElement>(null)

  const [startVisitDate, setStartVisitDate] = React.useState<Date>(now)
  const [endVisitDate, setEndVisitDate] = React.useState<Date>(now)
  const [visitTime, setVisitTime] = React.useState<TimePickerDuration>(config.registrationDefaultTime)
  const visitDateTime = React.useMemo(() => setMinutes(setHours(now, visitTime.hours), visitTime.minutes), [visitTime])
  const [visitedNames, setVisitedNames] = React.useState<string[]>(!!user ? [user.firstName, user.lastName] : [])
  const [selectMode, setSelectMode] = React.useState(false)
  const [selectedList, setSelectedList] = React.useState<string[]>([])
  const [status, setStatus] = React.useState<Status>('loading')
  const [registerStatus, setRegisterStatus] = React.useState<Status>('ok')
  const [modifyVisitId, setModifyVisitId] = React.useState<string>()
  const [initializedDates, setInitializedDate] = React.useState(true)

  const [myVisits, setMyVisits] = React.useState<PersonalVisitV6[]>([])
  const [datePickerToggle, setDatePickerToggle] = React.useState<'none' | 'start' | 'end'>('none')
  const [lastVisitors, setLastVisitors] = React.useState<VisitorV6[]>([])

  const [spacingDays, setSpacingDays] = React.useState(0)

  const visitsByDay = React.useMemo(
    () =>
      myVisits
        .reduce((acc, cur) => {
          const index = acc.findIndex((v) => v.date === cur.day)
          if (index > -1) {
            acc[index].visits.push(cur)
          } else {
            acc.push({ date: cur.day, visits: [cur] })
          }
          return acc
        }, [] as VisitsOfDay[])
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
    [myVisits]
  )

  const allVisitsButToday = myVisits.filter((v) => !isSameDay(new Date(), new Date(v.day)))

  const showedBanner = React.useMemo(() => banners.find((banner) => !!banner.feature && banner.feature === 'GUEST'), [
    banners,
  ])

  React.useEffect(() => {
    if (initializedDates) {
      setEndVisitDate(addBusinessDays(startVisitDate, spacingDays))
    }
  }, [startVisitDate, spacingDays, initializedDates])

  React.useEffect(() => {
    setSpacingDays(differenceInBusinessDays(endVisitDate, startVisitDate))
  }, [endVisitDate])

  React.useEffect(() => {
    if (!!user && !!user.officeId) {
      setVisitedNames([user.firstName, user.lastName])

      if (!!email) {
        graphApi
          .getUserByMail(user.type, email)
          .then((res) => {
            if (res) {
              setValues((prev) => ({ ...prev, firstName: res.givenName, lastName: res.surname, email: res.mail }))
            } else {
              setValues((prev) => ({ ...prev, email }))
            }
          })
          .catch(Logger.error)
      }

      getVisitsFunction()

      analytics.track({ screenName: analyticsKeys.guest, userType: user.type, currentSite: site, mySites })
    }
  }, [user, email])

  const registerForm = (values: Values) => {
    if (!!user && !!site) {
      setRegisterStatus('loading')
      if (values.email) {
        graphApi
          .getUserByMail(user.type, values.email)
          .then((res) => res === undefined)
          .then((external) => {
            register(site, user, external, values)
          })
          .catch((err) => {
            Logger.error(err)
            register(site, user, values.email ? isExternal(values.email) : true, values)
          })
      } else {
        register(site, user, true, values)
      }
    }
  }

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    isValid,
    dirty,
    handleSubmit,
    setValues,
    initialValues,
    touched,
    errors,
    setTouched,
  } = useFormik<Values>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      vehiclePlateNumber: '',
      company: '',
      visitedPhoneNumber: user?.mobilePhone || (!!user?.businessPhones && user.businessPhones[0]) || '',
      hostEngieUserId: user?.officeId || '',
    },
    onSubmit: registerForm,
    validationSchema: formValidationSchema(
      i18n,
      !!site && site.visitFormType === 'DEFAULT',
      !!site && site.visitFormType !== 'DEFAULT'
    ),
    validateOnChange: true,
  })

  const getVisitsFunction = () => {
    if (site && user) {
      setStatus('loading')
      Promise.all([accountApi.getVisits(site.id, user.type), api.getAllVisits(site.id, user.type)])
        .then((res) => {
          setLastVisitors(res[1].visitors || [])
          setMyVisits(res[0].visits)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  const excludeWeekEnds = (date: Date) => ![0, 6].includes(getDay(date))

  const isExternal = (email: string) => {
    const domain = email.toLowerCase().split('@')[1]
    INTERNAL_EMAILS.map((s) => {
      if (domain === s) {
        return false
      }
    })
    return true
  }

  const failureModal = (values: Values) => {
    Modal.open(() => (
      <ValidationContent
        title={i18n.t('screens.guest.registration.failure')}
        onConfirm={() => {
          Modal.close()
          registerForm(values)
        }}
        cancelButton
        confirmButtonTitle={i18n.t('common.retry')}
        cancelButtonTitle={i18n.t('common.ok')}
        ariaLabelConfirm={i18n.t('label.ariaLabel.guest.retryRegistration')}
        ariaLabelCancel={i18n.t('label.ariaLabel.guest.cancelRegistrationAttempt')}
      />
    ))
  }

  const specificErrorModal = (message: string) => {
    Modal.open(() => (
      <ValidationContent
        title={i18n.t('screens.guest.registration.failure')}
        onConfirm={Modal.close}
        description={message}
      />
    ))
  }

  const register = (site: SiteV6, user: UserSelf, external: boolean, vals: Values) => {
    if (vals.hostEngieUserId !== '') {
      if (modifyVisitId) {
        api
          .modifyVisit(modifyVisitId, site.id, user.type, {
            firstName: vals.firstName,
            lastName: vals.lastName,
            ...(vals.email && { email: vals.email }),
            external: external,
            visitingDay: i18n.t('screens.guest.form.dateFormat', { date: startVisitDate }),
            departureDay: i18n.t('screens.guest.form.dateFormat', { date: endVisitDate }),
            siteId: site.id,
            hostEngieUserId: vals.hostEngieUserId,
            ...(site.visitFormType === 'DEFAULT_ARRIVAL_PLATE' &&
              vals.vehiclePlateNumber && { vehiclePlateNumber: vals.vehiclePlateNumber }),
            ...((site.visitFormType === 'DEFAULT_ARRIVAL_PLATE' ||
              site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY') && {
              arrivalTime: i18n.t('screens.guest.form.timeFormat', { date: visitDateTime }),
            }),
            ...(site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY' && vals.company && { company: vals.company }),
            ...(vals.visitedPhoneNumber && { visitedPhoneNumber: vals.visitedPhoneNumber }),
            ...(site.visitFormType !== 'DEFAULT' && vals.comment && { comment: vals.comment }),
          })
          .then(() => {
            setModifyVisitId(undefined)
            getVisitsFunction()
            setValues((prev) => ({
              ...initialValues,
              hostEngieUserId: prev.hostEngieUserId,
              visitedPhoneNumber: prev.visitedPhoneNumber,
            }))
            setTouched({})
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('screens.guest.registration.modifySuccess')}
                onConfirm={Modal.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ))
            setRegisterStatus('ok')
          })
          .catch((err) => {
            Logger.error(err)
            if (err.code === 409) {
              Modal.open(() => (
                <ValidationContent
                  title={i18n.t('screens.guest.registration.alreadyRegisteredTitle')}
                  onConfirm={Modal.close}
                  description={i18n.t('screens.guest.registration.alreadyRegisteredText')}
                />
              ))
            } else if (!!err.data.message && !!err.data.error) {
              specificErrorModal(err.data.message)
            } else {
              failureModal(values)
            }
            setRegisterStatus('ok')
          })
      } else {
        api
          .registerUser(site.id, user.type, {
            firstName: vals.firstName,
            lastName: vals.lastName,
            ...(vals.email && { email: vals.email }),
            external: external,
            visitingDay: i18n.t('screens.guest.form.dateFormat', { date: startVisitDate }),
            departureDay: i18n.t('screens.guest.form.dateFormat', { date: endVisitDate }),
            siteId: site.id,
            hostEngieUserId: vals.hostEngieUserId,
            ...(site.visitFormType === 'DEFAULT_ARRIVAL_PLATE' &&
              vals.vehiclePlateNumber && { vehiclePlateNumber: vals.vehiclePlateNumber }),
            ...((site.visitFormType === 'DEFAULT_ARRIVAL_PLATE' ||
              site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY') && {
              arrivalTime: i18n.t('screens.guest.form.timeFormat', { date: visitDateTime }),
            }),
            ...(site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY' && vals.company && { company: vals.company }),
            ...(vals.visitedPhoneNumber && { visitedPhoneNumber: vals.visitedPhoneNumber }),
            ...(site.visitFormType !== 'DEFAULT' && vals.comment && { comment: vals.comment }),
          })
          .then(() => {
            getVisitsFunction()
            setValues((prev) => ({
              ...initialValues,
              hostEngieUserId: prev.hostEngieUserId,
              visitedPhoneNumber: prev.visitedPhoneNumber,
            }))
            setTouched({})
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('screens.guest.registration.success')}
                onConfirm={Modal.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ))
            setRegisterStatus('ok')
          })
          .catch((err) => {
            Logger.error(err)
            if (err.code === 409) {
              Modal.open(() => (
                <ValidationContent
                  title={i18n.t('screens.guest.registration.alreadyRegisteredTitle')}
                  onConfirm={Modal.close}
                  description={i18n.t('screens.guest.registration.alreadyRegisteredText')}
                />
              ))
            } else if (!!err.data.message && !!err.data.error) {
              specificErrorModal(err.data.message)
            } else {
              failureModal(values)
            }
            setRegisterStatus('ok')
          })
      }
    } else {
      setRegisterStatus('ok')
    }
  }

  const cancelVisits = (visitIdList: string[]) => {
    if (!!user && visitIdList.length > 0 && !!site) {
      setStatus('loading')

      const visitIdsFromSelectedVisitGroupIds = visitIdList.map(
        (visitOrGroupId) =>
          myVisits.find((v) => !!v.multiDayGroupId && v.multiDayGroupId === visitOrGroupId)?.visitId || visitOrGroupId
      )

      const filteredVisits = myVisits.filter(
        (visit) => visitIdList.includes(visit.visitId) || visitIdList.includes(visit.multiDayGroupId || '')
      )
      const zapfloorIdsToRemove = filteredVisits.reduce((acc, cur) => {
        if (cur.zapfloorBookings) {
          cur.zapfloorBookings.forEach((booking) => {
            if (booking.zapfloorId) {
              acc.push(booking.zapfloorId)
            }
          })
        }
        return acc
      }, [] as string[])

      Promise.all([
        ...visitIdsFromSelectedVisitGroupIds.map((v) => api.cancelVisit(site.id, user.type, v)),
        ...zapfloorIdsToRemove.map((id) => zapfloorApi.zapfloor.deleteBooking(user.type, site.id, id)),
      ])
        .then(() => {
          Modal.open(() => (
            <ValidationContent
              title={i18n.t('screens.guest.cancellation.success')}
              onConfirm={Modal.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
          getVisitsFunction()
          setSelectMode(false)
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('ok')
          openCancelModal(
            visitIdList,
            i18n.t('screens.desk.cancelationFailure'),
            i18n.t('common.retry'),
            i18n.t('common.ok')
          )
        })
    }
  }

  const removeZapfloorBooking = (zapfloorId: string) => {
    if (!!user && !!site) {
      setStatus('loading')

      zapfloorApi.zapfloorEngie
        .removeVisitorBooking(zapfloorId, user.type, site.id)
        .then(() => {
          zapfloorApi.zapfloor
            .deleteBooking(user.type, site.id, zapfloorId)
            .then(getVisitsFunction)
            .finally(() => setStatus('ok'))
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('ok')
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.surveys.details.error')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        })
    }
  }

  const circleOnClick = (visitId: string, isSelected: boolean) => {
    setSelectedList(isSelected ? selectedList.filter((s) => s !== visitId) : [...selectedList, visitId])
  }

  const openCancelModal = (visitIdList: string[], title: string, confirmTitle?: string, cancelTitle?: string) => {
    Modal.open(() => (
      <ValidationContent
        title={title}
        onConfirm={() => {
          Modal.close()
          cancelVisits(visitIdList)
        }}
        cancelButton
        confirmButtonTitle={confirmTitle || i18n.t('common.yes')}
        cancelButtonTitle={cancelTitle || i18n.t('common.no')}
        ariaLabelConfirm={i18n.t('label.ariaLabel.booking.confirmCancellation')}
        ariaLabelCancel={i18n.t('label.ariaLabel.booking.cancelCancellationAttempt')}
      />
    ))
  }

  const chooseVisitor = (contact: UserData) => {
    if (contact.email === '') {
      Alert.open(() => (
        <ValidationContent
          title={i18n.t('screens.directory.noEmail')}
          onConfirm={Alert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
        />
      ))
    } else {
      setValues((prev) => ({ ...prev, firstName: contact.firstname, lastName: contact.lastname, email: contact.email }))
      Modal.close()
    }
  }

  const chooseVisited = (visited: UserData) => {
    if (!!user) {
      graphApi
        .getUserByMail(user.type, visited.email)
        .then((graphUser) => {
          if (graphUser) {
            const phone = (graphUser.mobilePhone || graphUser.businessPhones[0]) as string | undefined
            if (!!phone) {
              setFieldValue('visitedPhoneNumber', phone.replace(' ', ''))
            } else {
              setFieldValue('visitedPhoneNumber', '')
            }
          } else {
            setFieldValue('visitedPhoneNumber', '')
          }
        })
        .catch(() => {
          setFieldValue('visitedPhoneNumber', '')
        })
        .finally(() => {
          setVisitedNames([visited.firstname, visited.lastname])
          setFieldValue('hostEngieUserId', visited.engieId)
          Modal.close()
        })
    }
  }

  const getVisitedInfo = (visitedId: string) => {
    if (!!user && !!site) {
      engieApi.getUsers(site.id, user.type, [visitedId]).then((res) => {
        if (res.users.length > 0) {
          setVisitedNames([res.users[0].firstName, res.users[0].lastName])
        }
      })
    }
  }

  const openTimePicker = () =>
    Modal.open(() => (
      <TimePickerInput
        value={visitTime}
        onChange={setVisitTime}
        close={Modal.close}
        title={i18n.t('screens.guest.form.time')}
        maxDuration={config.registrationMaxTime}
        minDuration={config.registrationMinTime}
      />
    ))

  const selectAll = () => {
    const allIds = allVisitsButToday.map((visit) => visit.multiDayGroupId ?? visit.visitId)
    setSelectedList(allIds.filter((id, index) => allIds.indexOf(id) === index))
  }

  const toggleSelectMode = () => {
    setSelectMode((b) => !b)
    setSelectedList([])
  }

  const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  const renderVisitDay = (v: VisitsOfDay) => (
    <DayContainer key={v.date}>
      <DateTitle>{i18n.t('screens.guest.visitDate', { date: getDayTimezone(v.date) })}</DateTitle>
      <VisitsContainer>
        {v.visits.map((visit, i, visits) => {
          const findVisits = myVisits.filter((myVisit) => myVisit.multiDayGroupId === visit.multiDayGroupId)
          const isVisitToday = findVisits.find((v) => isSameDay(new Date(), getDayTimezone(v.day)))
          const isTodayVisitCanBeModified =
            !isVisitToday || !site || todayVisitCanBeModified(isVisitToday, site.timezone)
          const hasZapfloorBookings = !!visit.zapfloorBookings && visit.zapfloorBookings.length > 0
          const visitCanBeModified = site?.id !== '1' && !hasZapfloorBookings && isTodayVisitCanBeModified
          return (
            <VisitInfo
              key={`${v.date} ${i}`}
              visit={visit}
              isLast={visits.length === i + 1}
              selectMode={selectMode}
              selectedList={selectedList}
              circleOnClick={circleOnClick}
              canModify={visitCanBeModified}
              onRemoveZapfloorBooking={removeZapfloorBooking}
              onCancel={(visitId, visitGroupId) => {
                const filteredVisits = myVisits
                  .filter((v) => v.multiDayGroupId === visitGroupId)
                  .sort((a, b) => compareAsc(new Date(a.day), new Date(b.day)))
                const firstVisit = filteredVisits.length > 0 ? filteredVisits[0] : undefined
                const lastVisit = filteredVisits.length > 0 ? filteredVisits[filteredVisits.length - 1] : undefined
                openCancelModal(
                  [visitId],
                  i18n.t(`screens.guest.cancellation.${filteredVisits.length > 1 ? 'confirmGroup' : 'confirm'}`, {
                    startDate: !!firstVisit ? new Date(firstVisit.day) : undefined,
                    endDate: !!lastVisit ? new Date(lastVisit.day) : undefined,
                  })
                )
              }}
              onModify={(visitId) => {
                if (formRef.current) {
                  formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                const findVisits = myVisits
                  .filter((myVisit) => myVisit.multiDayGroupId === visit.multiDayGroupId)
                  .sort((a, b) => compareAsc(new Date(a.day), new Date(b.day)))
                if (findVisits.length > 0) {
                  setInitializedDate(false)
                  setTimeout(() => setInitializedDate(true), 500)

                  setModifyVisitId(visitId)

                  setStartVisitDate(new Date(findVisits[0].day))
                  setEndVisitDate(new Date(findVisits[findVisits.length - 1].day))
                  if (!!visit.arrivalTime) {
                    const [hours, minutes] = visit.arrivalTime.split(':')
                    if (!!hours && !!minutes) {
                      setVisitTime({
                        hours: parseInt(hours),
                        minutes: parseInt(minutes),
                      })
                    }
                  }
                  getVisitedInfo(visit.visitedId)
                  setValues({
                    firstName: visit.visitorFirstName,
                    lastName: visit.visitorLastName,
                    vehiclePlateNumber: visit.vehiclePlateNumber || '',
                    hostEngieUserId: visit.visitedId,
                    comment: visit.comment || '',
                    email: visit.visitorEmail || '',
                    visitedPhoneNumber: visit.visitorPhoneNumber || '',
                  })
                }
              }}
            />
          )
        })}
      </VisitsContainer>
    </DayContainer>
  )

  if (!site) {
    return null
  }

  const renderGeneralInfos = () => (
    <ResponsiveFlexContainer marginBottom={20}>
      <ResponsiveFlex>
        <TitleAlignContainer>
          <InputTitle htmlFor="guestStartVisitDate" mandatory>
            {i18n.t('screens.guest.form.startVisit')}
          </InputTitle>
        </TitleAlignContainer>
        <VisitDate
          id="guestStartVisitDate"
          onClick={() => setDatePickerToggle('start')}
          tabIndex={0}
          onKeyDown={(e) => onEnterKey(e, () => setDatePickerToggle('start'))}
          aria-label={i18n.t('label.ariaLabel.guest.chooseStartVisitDate')}>
          {i18n.t('screens.guest.visitDate', { date: startVisitDate })}
        </VisitDate>
        {datePickerToggle === 'start' && (
          <CustomDatePicker
            selectedDate={startVisitDate}
            onChange={setStartVisitDate}
            minDate={new Date()}
            maxDate={maxRegistrationDate}
            closePicker={() => setDatePickerToggle('none')}
            filterDate={excludeWeekEnds}
          />
        )}
      </ResponsiveFlex>
      {(site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY' || site.visitFormType === 'DEFAULT_ARRIVAL_PLATE') && (
        <ResponsiveFlex>
          <TitleAlignContainer>
            <InputTitle htmlFor="guestVisitTime" mandatory>
              {i18n.t('screens.guest.form.time')}
            </InputTitle>
          </TitleAlignContainer>
          <VisitDate
            id="guestVisitTime"
            onClick={openTimePicker}
            tabIndex={0}
            onKeyDown={(e) => onEnterKey(e, openTimePicker)}
            aria-label={i18n.t('label.ariaLabel.guest.chooseVisitTime')}>
            {i18n.t('screens.guest.form.visitTime', { date: visitDateTime })}
          </VisitDate>
        </ResponsiveFlex>
      )}
      <ResponsiveFlex>
        <TitleAlignContainer>
          <InputTitle htmlFor="guestEndVisitDate" mandatory>
            {i18n.t('screens.guest.form.endVisit')}
          </InputTitle>
        </TitleAlignContainer>
        <VisitDate
          id="guestEndVisitDate"
          onClick={() => setDatePickerToggle('end')}
          tabIndex={0}
          onKeyDown={(e) => onEnterKey(e, () => setDatePickerToggle('end'))}
          aria-label={i18n.t('label.ariaLabel.guest.chooseEndVisitDate')}>
          {i18n.t('screens.guest.visitDate', { date: endVisitDate })}
        </VisitDate>
        {datePickerToggle === 'end' && (
          <CustomDatePicker
            selectedDate={endVisitDate}
            onChange={setEndVisitDate}
            minDate={startVisitDate}
            maxDate={addBusinessDays(startVisitDate, 4)}
            closePicker={() => setDatePickerToggle('none')}
            filterDate={excludeWeekEnds}
          />
        )}
      </ResponsiveFlex>
      {(site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY' || site.visitFormType === 'DEFAULT_ARRIVAL_PLATE') && (
        <ResponsiveFlex minWidth={350}>
          <FormTextInput
            id="comment"
            name="comment"
            onChange={(event) => setFieldValue('comment', event.target.value)}
            title={i18n.t('screens.guest.form.comment')}
            value={values.comment || ''}
            placeholder={i18n.t('screens.guest.form.commentPlaceholder')}
            titleAriaLabel={i18n.t('screens.guest.form.commentPlaceholder')}
          />
        </ResponsiveFlex>
      )}
    </ResponsiveFlexContainer>
  )

  const renderVisitorInfos = () => (
    <FormInfosContainer noMarginTop>
      <CategoryContainer>
        <CategoryTitle>{i18n.t('screens.guest.form.visitor')}</CategoryTitle>
        <SearchContactButtonContainer>
          <Button
            label={i18n.t('common.search')}
            onClick={() =>
              Modal.open(() => (
                <Directory
                  title={i18n.t('screens.guest.form.visitorIdentity')}
                  onSelectContact={(contact: UserData) => chooseVisitor(contact)}
                  searchAriaLabel={i18n.t('label.ariaLabel.guest.searchVisitorInDirectory')}
                  visitors={lastVisitors}
                />
              ))
            }
            font={Theme.fonts.label}
            verticalPadding={6}
            horizontalPadding={12}
          />
        </SearchContactButtonContainer>
      </CategoryContainer>
      <RowContainer>
        <ResponsiveFlex>
          <TitleAlignContainer>
            <InputTitle htmlFor="guestLastName" mandatory>
              {i18n.t('screens.guest.form.lastName')}
            </InputTitle>
          </TitleAlignContainer>
          <InputField
            id="guestLastName"
            autoComplete="family-name"
            name="lastName"
            aria-required="true"
            aria-describedby="guestLastNameError"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            placeholder={i18n.t('screens.guest.form.lastName')}
          />
          {touched.lastName && errors.lastName && <ErrorText id="guestLastNameError">{errors.lastName}</ErrorText>}
        </ResponsiveFlex>
        <ResponsiveFlex>
          <TitleAlignContainer>
            <InputTitle htmlFor="guestFirstName" mandatory>
              {i18n.t('screens.guest.form.firstName')}
            </InputTitle>
          </TitleAlignContainer>
          <InputField
            id="guestFirstName"
            autoComplete="given-name"
            name="firstName"
            aria-required="true"
            aria-describedby="guestFirstNameError"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            placeholder={i18n.t('screens.guest.form.firstName')}
          />
          {touched.firstName && errors.firstName && <ErrorText id="guestFirstNameError">{errors.firstName}</ErrorText>}
        </ResponsiveFlex>
        <ResponsiveFlex>
          <TitleAlignContainer>
            <InputTitle htmlFor="guestEmail" mandatory={site.visitFormType === 'DEFAULT'}>
              {i18n.t('screens.guest.form.email')}
            </InputTitle>
          </TitleAlignContainer>
          <InputField
            id="guestEmail"
            autoComplete="email"
            aria-required="true"
            aria-describedby="guestEmailError"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder={i18n.t('screens.guest.form.email')}
            spellCheck={false}
            autoCorrect="off"
            autoCapitalize="off"
          />
          {touched.email && errors.email && <ErrorText id="guestEmailError">{errors.email}</ErrorText>}
        </ResponsiveFlex>
        {site.visitFormType === 'DEFAULT_ARRIVAL_PLATE' && (
          <ResponsiveFlex>
            <TitleAlignContainer>
              <InputTitle htmlFor="guestVehiclePlateNumber">{i18n.t('screens.guest.form.immat')}</InputTitle>
            </TitleAlignContainer>
            <InputField
              id="guestVehiclePlateNumber"
              name="vehiclePlateNumber"
              onChange={(event) => setFieldValue('vehiclePlateNumber', event.target.value.toUpperCase())}
              onBlur={handleBlur}
              value={values.vehiclePlateNumber}
              placeholder={i18n.t('screens.guest.form.immat')}
              spellCheck={false}
              autoCorrect="off"
              autoCapitalize="on"
            />
          </ResponsiveFlex>
        )}
        {site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY' && (
          <ResponsiveFlex>
            <TitleAlignContainer>
              <InputTitle htmlFor="guestCompany">{i18n.t('screens.guest.form.company')}</InputTitle>
            </TitleAlignContainer>
            <InputField
              id="guestCompany"
              name="company"
              onChange={(event) => setFieldValue('company', event.target.value)}
              onBlur={handleBlur}
              value={values.company}
              placeholder={i18n.t('screens.guest.form.company')}
              spellCheck={false}
              autoCorrect="off"
            />
          </ResponsiveFlex>
        )}
      </RowContainer>
    </FormInfosContainer>
  )

  const renderVisitedInfos = () => (
    <FormInfosContainer noMarginTop>
      <CategoryContainer>
        <CategoryTitle>{i18n.t('screens.guest.form.visited')}</CategoryTitle>
        <SearchContactButtonContainer>
          <Button
            label={i18n.t('common.search')}
            onClick={() =>
              Modal.open(() => (
                <Directory
                  title={i18n.t('screens.guest.form.visitedIdentity')}
                  onSelectContact={(contact: UserData) => chooseVisited(contact)}
                  searchAriaLabel={i18n.t('label.ariaLabel.guest.searchVisitedInDirectory')}
                  engieDirectory
                />
              ))
            }
            font={Theme.fonts.label}
            verticalPadding={6}
            horizontalPadding={12}
          />
        </SearchContactButtonContainer>
      </CategoryContainer>
      <RowContainer>
        <ResponsiveFlex>
          <VisitorTitleContainer>
            <InputTitle htmlFor="guestVisited" mandatory>
              {i18n.t('screens.guest.form.visitedIdentity')}
            </InputTitle>
          </VisitorTitleContainer>
          <VisitedIdentity visitedSelectionned={visitedNames.length > 0} id="guestVisited">
            {i18n.t(`screens.guest.${visitedNames.length > 0 ? 'fullName' : 'form.visitedIdentity'}`, {
              firstname: visitedNames[0],
              lastname: visitedNames[1],
            })}
          </VisitedIdentity>
        </ResponsiveFlex>
        {(site.visitFormType === 'DEFAULT_ARRIVAL_COMPANY' || site.visitFormType === 'DEFAULT_ARRIVAL_PLATE') && (
          <ResponsiveFlex>
            <TitleAlignContainer>
              <InputTitle htmlFor="visitedPhoneNumber" mandatory>
                {i18n.t('screens.guest.form.visitedPhoneNumber')}
              </InputTitle>
            </TitleAlignContainer>
            <InputField
              id="visitedPhoneNumber"
              aria-required="true"
              aria-describedby="visitedPhoneNumberError"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.visitedPhoneNumber}
              placeholder={i18n.t('screens.guest.form.visitedPhoneNumberPlaceholder')}
              spellCheck={false}
              autoCorrect="off"
              autoCapitalize="off"
            />
            <ErrorText id="visitedPhoneNumberError">
              {touched.visitedPhoneNumber && errors.visitedPhoneNumber}
            </ErrorText>
          </ResponsiveFlex>
        )}
      </RowContainer>
    </FormInfosContainer>
  )

  return (
    <MainContainer>
      <TitleHelmet title={i18n.t('screens.guest.title')} />
      <Breadcrumb screen="guest" path="guest" navigation={navigation} />

      <TopContainer ref={formRef}>
        {!!showedBanner && <Banner banner={showedBanner} marginBottom={20} />}
        <TitleAlignContainer>
          <Title>{i18n.t(`screens.guest.${!!modifyVisitId ? 'modifyRegistration' : 'newVisit'}`)}</Title>
        </TitleAlignContainer>
        <FormContainer>
          {renderGeneralInfos()}
          <RowContainer>
            {renderVisitorInfos()}
            {renderVisitedInfos()}
          </RowContainer>
          <RegisterButtonContainer>
            <Button
              type="submit"
              label={i18n.t(`screens.guest.${!!modifyVisitId ? 'registerModifications' : 'register'}`)}
              verticalPadding={14}
              font={Theme.fonts.h3Bold}
              onClick={handleSubmit}
              disabled={!(isValid && dirty) || registerStatus === 'loading'}
              ariaLabel={i18n.t('label.ariaLabel.guest.registerButton')}
              loading={registerStatus === 'loading'}
            />
          </RegisterButtonContainer>
        </FormContainer>
      </TopContainer>

      <BottomContainer disabled={!selectMode} autoFocus={false}>
        <TitleContainer nbColumn={visitsByDay.length}>
          <Title>{i18n.t('screens.guest.visitors')}</Title>
          <SelectOptions>
            {selectMode && (
              <SelectAll onClick={selectAll} aria-label={i18n.t('label.ariaLabel.guest.selectAllVisits')}>
                {i18n.t('common.selectAll')}
              </SelectAll>
            )}
            {allVisitsButToday.length !== 0 && (
              <SelectVisitor
                onClick={toggleSelectMode}
                aria-label={i18n.t(`label.ariaLabel.guest.${selectMode ? 'cancelSelectVisits' : 'selectVisits'}`)}>
                {i18n.t(`common.${selectMode ? 'cancel' : 'select'}`)}
              </SelectVisitor>
            )}
          </SelectOptions>
        </TitleContainer>
        {status === 'loading' ? (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        ) : status === 'error' ? (
          <NoVisitsMessage>{i18n.t('screens.guest.error')}</NoVisitsMessage>
        ) : myVisits.length === 0 ? (
          <NoVisitsMessage>{i18n.t('screens.guest.noVisits')}</NoVisitsMessage>
        ) : (
          <>
            <GridContainer>{visitsByDay.map(renderVisitDay)}</GridContainer>
            {selectMode && selectedList.length > 0 && (
              <DeleteButtonPositionContainer nbColumn={visitsByDay.length}>
                <DeleteButtonContainer>
                  <Button
                    label={i18n.t('screens.guest.deleteSelection')}
                    onClick={() =>
                      openCancelModal(
                        selectedList,
                        i18n.t('screens.guest.cancellation.confirm', { count: selectedList.length })
                      )
                    }
                    font={Theme.fonts.h3Bold}
                  />
                </DeleteButtonContainer>
              </DeleteButtonPositionContainer>
            )}
          </>
        )}
      </BottomContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 20px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 0px;
    padding: 20px;
  }
`
const BottomContainer = styled(FocusLock)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 0px 60px 102px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    padding: 40px 50px 30px 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 40px 20px 60px 20px;
    justify-content: center;
  }
`
const SearchContactButtonContainer = styled('div')`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
`
const VisitorTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 0px;
`
const RegisterButtonContainer = styled('div')`
  display: flex;
  max-width: 500px;
  margin-top: 20px;
`
const TitleContainer = styled('div')<{ nbColumn: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.nbColumn <= 1 ? 367 : props.nbColumn === 2 ? 744 : 1121)}px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    width: ${(props) => (props.nbColumn <= 1 ? 367 : 744)}px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0px 5px;
    width: 100%;
  }
`
const SelectOptions = styled('div')`
  display: flex;
  margin: 0px 20px;
`
const GridContainer = styled('div')`
  margin-top: 20px;
  display: grid;
  width: 1131px;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    width: 744px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`
const DayContainer = styled('div')`
  flex: 1;
  max-width: 400px;
  margin: 0px 10px 10px 0px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin: 0 0 10px 0;
  }
`
const VisitsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  padding: 6px 20px 5px 20px;
`
const DeleteButtonPositionContainer = styled('div')<{ nbColumn: number }>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.nbColumn <= 1 ? 367 : props.nbColumn === 2 ? 744 : 1121)}px;
  margin-top: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    width: ${(props) => (props.nbColumn <= 1 ? 367 : 744)}px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 357px;
  }
`
const DeleteButtonContainer = styled('div')`
  display: flex;
  width: 327px;
  height: 50px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`
const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 30px;
`
const FormInfosContainer = styled('div')<{ noMarginTop?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  margin: ${(props) => (props.noMarginTop ? 0 : 20)}px 20px ${(props) => (props.noMarginTop ? 10 : 30)}px 0;
  padding: 20px;
  gap: 20px 30px;
  align-content: flex-start;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
`
const ResponsiveFlexContainer = styled('div')<{ marginBottom?: number }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px 30px;
  ${(props) => !!props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
`
const ResponsiveFlex = styled('div')<{ minWidth?: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: ${(props) => props.minWidth || 250}px;
  max-width: 100%;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`
const TitleAlignContainer = styled('div')`
  display: flex;
  align-items: center;
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.contentTitle};
  font-size: 24px;
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const InputTitle = styled('label')<{ mandatory?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  ${(props) =>
    props.mandatory &&
    `
    ::after {
      color: ${props.theme.colors.red};
      content: "*";
      position: relative;
      left: 4px;
    }
  `}
`
const InputField = styled('input')`
  ${(props) => props.theme.fonts.bodyBold};
  border: 0;
  min-width: 250px;
  outline: 0;
  color: ${(props) => props.theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
  background: transparent;
  padding: 10px 0px;
  margin: 0;
  ::placeholder {
    font-weight: 300;
  }
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.blue};
  }
`
const VisitDate = styled('p')`
  cursor: pointer;
  text-transform: capitalize;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 10px 0px 0px 0px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const VisitedIdentity = styled('p')<{ visitedSelectionned: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  ${(props) => !props.visitedSelectionned && 'font-weight: 300'};
  color: ${(props) => (props.visitedSelectionned ? props.theme.colors.blue : props.theme.colors.placeholderGrey)};
  margin: 10px 0px 0px 0px;
`
const SelectVisitor = styled('button')`
  cursor: pointer;
  ${(props) => props.theme.fonts.linkUnderlined};
  color: ${(props) => props.theme.colors.darkGrey};
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0px;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const SelectAll = styled('button')`
  cursor: pointer;
  ${(props) => props.theme.fonts.linkUnderlined};
  color: ${(props) => props.theme.colors.darkGrey};
  text-decoration: underline;
  margin-right: 20px;
  background-color: transparent;
  border: 0;
  padding: 0px;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const NoVisitsMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`
const DateTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 8px 0px 7px 20px;
  text-transform: capitalize;
`
const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  color: ${(props) => props.theme.colors.red};
  min-height: 20px;
  margin: 0px 10px 0px 0px;
`

// AUTRES

const FormContainer = styled('form')`
  margin-top: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 35px;
  }
`

const CategoryContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

const CategoryTitle = styled('p')`
  ${(props) => props.theme.fonts.contentTitle};
  margin: 0px;
`

export default RegistrationScreen
