import * as React from 'react'

import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icons'

import { getBannerIconInfos } from './utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'

interface Props {
  banner: BannerV6
  marginTop?: number
  marginBottom?: number
  marginRight?: number
  marginLeft?: number
}

const Banner = ({ banner, marginTop = 0, marginBottom = 0, marginRight = 0, marginLeft = 0 }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const [isClosed, setIsClosed] = React.useState(false)

  const { description, title, status } = banner

  const bannerIconInfos = getBannerIconInfos(status, theme)

  if (isClosed) {
    return null
  }

  return (
    <BannerContainer
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginRight={marginRight}
      marginLeft={marginLeft}>
      {status === 'NEWS' && (
        <RowContainer>
          <NewContainerLinearGradient>
            <New>{i18n.t('common.new')}</New>
          </NewContainerLinearGradient>
          <CrossContainer isFlex>
            <Cross onClick={() => setIsClosed(true)}>
              <Icon name="cross" size={24} />
            </Cross>
          </CrossContainer>
        </RowContainer>
      )}
      <RowContainer>
        {!!bannerIconInfos && (
          <IconContainer>
            <Icon name={bannerIconInfos.icon} size={24} color={bannerIconInfos.color} />
          </IconContainer>
        )}
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        {!!bannerIconInfos && (
          <CrossContainer>
            <Cross onClick={() => setIsClosed(true)}>
              <Icon name="cross" size={24} />
            </Cross>
          </CrossContainer>
        )}
      </RowContainer>
      <DescriptionContainer noPadding={status === 'NEWS'}>
        <Description>{description}</Description>
      </DescriptionContainer>
    </BannerContainer>
  )
}

export default Banner

const BannerContainer = styled('div')<{
  marginTop: number
  marginBottom: number
  marginLeft: number
  marginRight: number
}>`
  padding: 12px;
  gap: 8px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  border-radius: 5px;
  margin: ${(props) => `${props.marginTop}px ${props.marginRight}px ${props.marginBottom}px ${props.marginLeft}px`};
`

const Title = styled('p')`
  margin: 0;
  ${(props) => props.theme.fonts.subtitleBold};
`
const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  align-items: center;
`

const IconContainer = styled('div')`
  padding-right: 8px;
`
const TitleContainer = styled('div')`
  display: flex;
  flex: 1;
`

const CrossContainer = styled('div')<{ isFlex?: boolean }>`
  display: flex;
  ${(props) => props.isFlex && 'flex: 1;'}
  justify-content: flex-end;
`

const Cross = styled('div')`
  padding-left: 12px;
  cursor: pointer;
`

const DescriptionContainer = styled('div')<{ noPadding?: boolean }>`
  ${(props) => !props.noPadding && 'padding-left: 32px;'}
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 0;
  }
`

const Description = styled('p')`
  margin: 0;
  ${(props) => props.theme.fonts.label};
`

const NewContainerLinearGradient = styled('div')`
  padding: 4px;
  border-radius: 4px;
  background: linear-gradient(135deg, rgba(0, 188, 253) 0%, rgba(35, 210, 181) 100%);
`

const New = styled('p')`
  margin: 0;
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
`
