import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'
import useI18n from 'i18n/useI18n'

import SingleValueWheelPicker from 'components/picker/SingleValueWheelPicker'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'

const ModalContent = (
  title: string,
  limit: number,
  selectedChoice: number,
  setChoice: React.Dispatch<React.SetStateAction<number>>
) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const [ownOption, setOwnOption] = React.useState(selectedChoice)

  const options = Array.from(Array(limit), (_, index) => index + 1)

  const valuesText = options.map((o) => (o < limit ? o.toString() : i18n.t('screens.room.countPlus', { count: limit })))

  return (
    <>
      <MainContainer>
        <ModalTitle>{title}</ModalTitle>
        <SingleValueWheelPicker
          values={options}
          selectedValue={ownOption}
          setValue={(value: number) => setOwnOption(value)}
          allValues={options}
          displayedValuesText={valuesText}
        />
      </MainContainer>
      <ButtonContainer>
        <Button
          label={i18n.t('common.validate')}
          onClick={() => {
            setChoice(ownOption)
            Modal.close()
          }}
          font={theme.fonts.h3Bold}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
        />
      </ButtonContainer>
    </>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 15px 60px 25px 60px;
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  margin-top: 10px;
`

// TEXTES

const ModalTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin-top: 16px;
`

export default ModalContent
