import * as React from 'react'
import styled from 'theme/styled-components'
import ZoomImage from 'components/image/ZoomImage'
import Icon from 'components/icons/Icons'
import useI18n from 'i18n/useI18n'
import useTheme from 'theme/useTheme'

import { windowSizeBreakpoints } from 'utils/breakpoints'

interface Props {
  photosUrl: string[]
  height?: number
  width?: number
  fullWidth?: boolean
}

const CarouselRework = ({ photosUrl, height = 310, width = 500, fullWidth = false }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const [selectedPhotoUrl, setSelectedPhotoUrl] = React.useState(photosUrl.length > 0 ? photosUrl[0] : undefined)
  const [index, setIndex] = React.useState(0)

  if (!selectedPhotoUrl) {
    return null
  }

  const zoomImage = () => ZoomImage.open(selectedPhotoUrl)

  return (
    <MainContainer>
      {index > 0 && (
        <ArrowContainer
          height={height}
          onClick={() => {
            setIndex(index - 1)
            setSelectedPhotoUrl(photosUrl[index - 1])
          }}
          left>
          <Icon
            color={Theme.colors.white}
            size={22}
            name="arrow_right"
            ariaLabel={i18n.t('label.svg.carrouselArrowLeft')}
          />
        </ArrowContainer>
      )}
      <Photo
        src={selectedPhotoUrl}
        onClick={zoomImage}
        onKeyPress={(evt) => {
          if (evt.key === 'Enter' || evt.key === ' ') {
            zoomImage()
          }
        }}
        height={height}
        width={width}
        fullWidth={fullWidth}
      />
      {photosUrl.length > 0 && index < photosUrl.length - 1 && (
        <ArrowContainer
          height={height}
          onClick={() => {
            setIndex(index + 1)
            setSelectedPhotoUrl(photosUrl[index + 1])
          }}>
          <Icon
            color={Theme.colors.white}
            size={22}
            name="arrow_right"
            ariaLabel={i18n.t('label.svg.carrouselArrowRight')}
          />
        </ArrowContainer>
      )}
    </MainContainer>
  )
}

export default CarouselRework

const MainContainer = styled('div')`
  display: flex;
  position: relative;
`

const Photo = styled('img')<{ height: number; width: number; fullWidth?: boolean }>`
  height: ${(props) => props.height}px;
  width: ${(props) => (!!props.fullWidth ? '100%' : `${props.width}px`)};
  object-fit: cover;
  user-select: none;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
  :hover {
    transform: scale(1.02);
  }
`

const ArrowContainer = styled.div<{
  height: number
  left?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  top: ${(props) => (props.height - 32) / 2}px;
  ${(props) => !!props.left && 'left: 12px;'}
  ${(props) => !props.left && 'right: 12px;'}
${(props) => !!props.left && 'transform: rotate(180deg)'};

  background-color: ${(props) => props.theme.colors.black};
  :hover {
    background-color: ${(props) => props.theme.colors.blue};
  }
`
