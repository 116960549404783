import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'
import useBreakpoint from 'utils/useBreakpoints'

import Back from './Back'

import utils from './utils'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'

import { setHours, startOfDay, max as findMax, min as findMin, isBefore, isSameDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

interface Props {
  meetings: RoomReservationV6[]
  min?: number
  max?: number
  day: Date
  setDay: (date: Date) => void
  daysList: Date[]
  timezone?: string
}

const REF_DATE = startOfDay(new Date())

const OCCUPANCY_HEIGHT = 12
const HOUR_HEIGHT = 30

const MARGIN = 10

const FIRST_POSITION = HOUR_HEIGHT + MARGIN - OCCUPANCY_HEIGHT + 0.5
const SECOND_POSITION = 2 * HOUR_HEIGHT + MARGIN * 2 - OCCUPANCY_HEIGHT + 1.5

const transformDate = (date: Date, hoursToSet: number, timeZoneToApply: string) => {
  const { timeZone: clientTimezone } = Intl.DateTimeFormat().resolvedOptions()
  return zonedTimeToUtc(setHours(zonedTimeToUtc(date, clientTimezone), hoursToSet), timeZoneToApply)
}

const Planning = ({ meetings, min = 8, max = 20, day, setDay, daysList, timezone }: Props) => {
  const middle = React.useMemo(() => Math.ceil((max + min) / 2), [min, max])

  const timezoneToApply = timezone || useReducer(siteStore.store, (s) => s.site?.timezone) || 'UTC'

  const middleDate = React.useMemo(() => transformDate(day, middle, timezoneToApply), [day, middle])
  const minDate = React.useMemo(() => transformDate(day, min, timezoneToApply), [day, min])
  const maxDate = React.useMemo(() => transformDate(day, max, timezoneToApply), [day, max])

  const [, currentBreakpoint] = useBreakpoint()

  const currentIndex = React.useMemo(() => daysList.findIndex((d) => isSameDay(d, day)), [daysList, day])

  const dimensions = currentBreakpoint === 'small' ? 70 : currentBreakpoint === 'phone' ? 55 : 80

  const onKeyDownFunction = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft' && currentIndex > 0) {
      setDay(daysList[currentIndex - 1])
    } else if (e.key === 'ArrowRight' && currentIndex < daysList.length - 1) {
      setDay(daysList[currentIndex + 1])
    }
  }

  const RenderFirstBackComponent = React.useCallback(
    () => <Back start={min} end={middle} width={dimensions} refDate={REF_DATE} />,
    [max, min, middle, dimensions]
  )
  const RenderSecondBackComponent = React.useCallback(
    () => <Back start={middle} end={max} width={dimensions} refDate={REF_DATE} />,
    [max, min, middle, dimensions]
  )

  const renderMeeting = (m: RoomReservationV6) => {
    const start = findMax([new Date(m.from), minDate])
    const end = findMin([new Date(m.to), maxDate])

    const isFirst = isBefore(start, middleDate)
    const isSecond = !isBefore(end, middleDate)

    if (isFirst && isSecond) {
      // case meeting over the middle

      const duration1 = utils.calculateWidthFromMinutes(dimensions, start, middleDate)
      const duration2 = utils.calculateWidthFromMinutes(dimensions, middleDate, end)

      const paddingLeft = utils.calculatePaddingFromMinutes(dimensions, start, minDate)

      return (
        <div key={m.from}>
          <MeetingBook top={FIRST_POSITION} width={duration1} left={paddingLeft} />
          <MeetingBook top={SECOND_POSITION} width={duration2} left={0} />
        </div>
      )
    }

    const duration = utils.calculateWidthFromMinutes(dimensions, start, end)
    const left = utils.calculatePaddingFromMinutes(dimensions, start, isFirst ? minDate : middleDate)

    return <MeetingBook key={m.from} top={isFirst ? FIRST_POSITION : SECOND_POSITION} width={duration} left={left} />
  }

  const renderDays = (item: Date) => {
    const i18n = useI18n()
    const formatedDate = i18n.t('screens.room.details.planning.weekDayFormat', { date: item })
    const active = isSameDay(day, item)

    return (
      <DayContainer key={formatedDate}>
        <Day selected={active} onClick={() => setDay(item)}>
          <DayText selected={active}>{formatedDate}</DayText>
        </Day>
      </DayContainer>
    )
  }

  return (
    <MainContainer>
      <DaysScroller tabIndex={0} onKeyDown={(e) => onKeyDownFunction(e)}>
        <DaysContainer index={currentIndex}>{daysList.map(renderDays)}</DaysContainer>
      </DaysScroller>
      <PlanningContainer>
        <PlanningRelativeContainer>
          <RenderFirstBackComponent />
          <RenderSecondBackComponent />
          {meetings.map(renderMeeting)}
        </PlanningRelativeContainer>
      </PlanningContainer>
    </MainContainer>
  )
}

export default Planning

const MainContainer = styled('div')`
  flex-direction: column;
  flex: 1;
`
const DaysScroller = styled('div')`
  position: relative;
  align-items: center;
  overflow: hidden;
  width: 100%;
  align-items: center;
  height: 25px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const DaysContainer = styled('div')<{ index: number }>`
  position: absolute;
  display: flex;
  left: ${(props) => 178 - props.index * 104}px;
  transition: left 0.15s linear;
`
const DayContainer = styled('div')`
  margin: 0px 10px;
`
const PlanningContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin-top: 13px;
`

const PlanningRelativeContainer = styled('div')`
  position: relative;
`

const Day = styled('div')<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.mediumDarkGrey)};
  border-radius: 4px;
  padding: 3px 0px;
  width: 84px;
`
const DayText = styled('p')<{ selected: boolean }>`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.darkGrey)};
  text-align: center;
  width: 84px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
const MeetingBook = styled('div')<{ top: number; left: number; width: number }>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width + 0.5}px;
  height: ${OCCUPANCY_HEIGHT}px;
  background-color: ${(props) => props.theme.colors.occupied};
`
