import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getBanners: (userType: UserType, siteId: string) =>
    assureToken(userType).then((token) =>
      Webservice<BannersV6>(userType, `${config.globalAPIVersion}/banners?siteId=${siteId}&currentBanner=true`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
