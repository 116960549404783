import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import stringUtils from 'utils/strings'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import utils from './utils'

import api from './api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'

import Icons from 'components/icons/Icons'
import ContactInfosGraph from './ContactInfosGraph'

import Loader from 'react-loader-spinner'

type Status = 'ok' | 'error' | 'loading' | 'empty'

const MIN_CHARACTERS = 3

interface Props {
  onSelectContact: (contact: DirectoryUser) => void
  searchAriaLabel?: string
}

const GraphDirectory = ({ onSelectContact, searchAriaLabel }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const userType = useReducer(userStore.store, (s) => s.user?.type || 'ENGIE')

  const [searchText, setSearchText] = React.useState<string>('')
  const [searchedUsers, setSearchedUsers] = React.useState<DirectoryUser[]>([])
  const [status, setStatus] = React.useState<Status>()

  const sortContactsByLetter = React.useMemo(() => {
    if (searchedUsers) {
      return utils.sortUsersByLetter(searchedUsers)
    }
  }, [searchedUsers])

  React.useEffect(() => {
    if (searchText.length < 3) {
      setStatus('empty')
      setSearchedUsers([])
    }

    const timer = setTimeout(() => searchContacts(searchText), 500)

    return () => clearTimeout(timer)
  }, [searchText])

  const searchContacts = (searchText: string) => {
    if (searchText.length >= MIN_CHARACTERS) {
      const words = stringUtils.searchToWords(searchText)

      setStatus('loading')
      api
        .fetchUsers(userType, words)
        .then((users) =>
          setSearchedUsers(
            users
              .filter((user) => !!user.surname && !!user.givenName)
              .sort((a, b) => a.surname.localeCompare(b.surname))
          )
        )
        .then(() => setStatus('ok'))
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    } else {
      setStatus(undefined)
    }
  }

  const clearSearch = () => {
    setSearchText('')
    searchContacts('')
  }

  return (
    <MainContainer>
      <SearchContainer>
        <SearchBarContainer>
          <SearchBar
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={i18n.t('screens.directory.search')}
            autoFocus
            aria-label={searchAriaLabel}
            aria-labelledby="SearchIndications"
          />
          {searchText.length > 0 && (
            <CrossContainer onClick={clearSearch}>
              <Icons name="cross" size={17} color={Theme.colors.blue} />
            </CrossContainer>
          )}
          <IconContainer>
            <Icons name="search" size={15} color={Theme.colors.blue} />
          </IconContainer>
        </SearchBarContainer>
      </SearchContainer>
      {status === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : status === 'ok' ? (
        sortContactsByLetter && sortContactsByLetter.length > 0 ? (
          <ListContainer>
            {sortContactsByLetter.map((data) => (
              <div key={data.letter}>
                <CategoryLetter>{data.letter}</CategoryLetter>
                {data.users.map((u, i) => (
                  <ContactInfosGraph
                    contact={u}
                    isLast={data.users.length === i + 1}
                    key={u.id}
                    onSelectContact={onSelectContact}
                  />
                ))}
              </div>
            ))}
          </ListContainer>
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('screens.directory.noResults')}</ErrorMessage>
          </ErrorMessageContainer>
        )
      ) : (
        <ErrorMessageContainer>
          <ErrorMessage id="SearchIndications">
            {i18n.t(`screens.directory.${status === 'error' ? 'error' : 'minimumChar'}`, { minimum: MIN_CHARACTERS })}
          </ErrorMessage>
        </ErrorMessageContainer>
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
  background-color: ${(props) => props.theme.colors.white};
`
const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 40px;
  padding: 10px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 12px 0;
  }
`
const SearchBarContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
`
const CrossContainer = styled('button')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ListContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  margin: 0 40px;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0 12px;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    min-width: 300px;
  }
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0 12px;
  }
`

// TEXTES

const CategoryLetter = styled('h4')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 5px 0px 6px 0px;
  padding: 7px 0px 6px 24px;
  text-transform: capitalize;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin-top: 20px;
  text-align: center;
`

// AUTRES

const SearchBar = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.label}
  margin-left: 24px;
  border: 0;
  outline: 0;
`

export default GraphDirectory
